import Constant from '@/const';

const axios = require('axios');

export default {
  namespaced: true,
  state: {
    consumables: [],
    total: 0,
  },
  getters: {
    consumables: state => state.consumables,
  },
  mutations: {
    saveConsumables(state, payload) {
      state.consumables = payload.consumables;
    },
    removeConsumables(state) {
      state.consumables = [];
    },
  },
  actions: {
    postConsumables({ getters, rootGetters }) {
      // const drugs = getters.consumables.filter(item => item.type === 1);
      // const goods = getters.consumables.filter(item => item.type === 0);
      // const drugs = getters.consumablesAndGas.consumables.data.filter(item => item.type === 1);
      // const goods = getters.consumablesAndGas.consumables.data.filter(item => item.type === 0);
      const postConsumables = axios.post(
        Constant.apiUrl.concat('/patient/bhp/').concat(rootGetters.selectedPatient.id_queue),
        {
          detail: [
            {
              detail_history: [
                {
                  detail_bhp: getters.consumables.map(data => {
                    return {
                      bhp: {
                        id_goods: data.type === 0 ? data.id : '000000000000000000000000',
                        id_drugs: data.type === 1 ? data.id : '000000000000000000000000',
                        name_item: data.text,
                        min_stock: data.min_stock,
                      },
                      quantity: [data.last_qty,data.qty],
                      type: data.unit,
                    };
                  }),
                  timestamps: {
                    created_by: rootGetters.userLoggedIn.id,
                  },
                },
              ],
            },
          ],
        },
      );
      /*
      const putGoodsStock = axios.put(Constant.apiUrl.concat('/masterdata/goods/stock/'), {
        detail_stock: goods.map(item => {
          return {
            id_goods: item.id,
            stock: item.qty,
          };
        }),
      });

      const putDrugsStock = axios.put(Constant.apiUrl.concat('/masterdata/drugs/stock/'), {
        detail_stock: drugs.map(item => {
          return {
            id_drugs: item.id,
            stock: item.qty,
          };
        }),
      }); */
      // return axios.all([postConsumables, putGoodsStock, putDrugsStock]);
      return axios.all([postConsumables]);
    },
  },
};
