export default {
  namespaced: true,
  state: {
    lastTabUpdate: false,
    onUpdate: false,
    incomingPatientData: null,
    history: null,
    painAssessment: null,
    physicalExam: null,
    supportingInvestigation: null,
    diagnosisAndAction: null,
  },
  getters: {
    statusLastTab: (state) => state.lastTabUpdate,
    onUpdate: (state) => state.onUpdate,
    incomingPatientData: (state) => state.incomingPatientData,
    assesmentData: (state) => {
      return {
        incomingPatientData: state.incomingPatientData,
        history: state.history,
        painAssessment: state.painAssessment,
        phsicalExam: state.physicalExam,
        supportingInvestigation: state.supportingInvestigation,
        diagnosisAndAction: state.diagnosisAndAction,
      };
    },
    history: (state) => state.history,
    painAssessment: (state) => state.painAssessment,
    physicalExam: (state) => state.physicalExam,
    supportingInvestigation: (state) => state.supportingInvestigation,
    diagnosisAndAction: (state) => state.diagnosisAndAction,
  },
  mutations: {
    setLastTab(state, payload) {
      console.log('set status');
      state.lastTabUpdate = payload;
    },
    changeOnUpdate(state, payload) {
      state.onUpdate = payload;
    },
    saveAssessment(state, payload) {
      state[payload.key] = payload.data;
    },
    removeAssessment(state) {
      state.incomingPatientData = null;
      state.history = null;
      state.painAssessment = null;
      state.physicalExam = null;
      state.supportingInvestigation = null;
      state.diagnosisAndAction = null;
    },
  },
};
