export default {
  namespaced: true,
  state: {
    globalInterval: null,
  },
  getters: {
    globalInterval: state => state.globalInterval,
  },
  mutations: {
    setGlobalInterval(state, payload) {
      state.globalInterval = setInterval(
        payload.task,
        payload.timer || 5 * 60000,
      );
    },
    clearGlobalInterval(state) {
      clearInterval(state.globalInterval);
      state.globalInterval = null;
    },
  },
  actions: {
    startGlobalInterval({ state, commit }, payload) {
      if (state.globalInterval !== null) {
        commit('clearGlobalInterval');
      }
      payload.task();
      commit('setGlobalInterval', payload);
    },
    stopGlobalInterval({ commit }) {
      commit('clearGlobalInterval');
    },
  },
};
