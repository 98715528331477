export default {
  namespaced: true,
  state: {
    onUpdate: false,
    obstetrics_status: undefined,
    neonate_status: undefined,
    physical_condition: undefined,
    diagnose_and_therapy: undefined,
  },
  getters: {
    onUpdate: (state) => state.onUpdate,
    assessmentData: (state) => {
      return {
        obstetrics_status: state.obstetrics_status,
        neonate_status: state.neonate_status,
        physical_condition: state.physical_condition,
        diagnose_and_therapy: state.diagnose_and_therapy,
      };
    },
  },
  mutations: {
    changeOnUpdate(state, payload) {
      state.onUpdate = payload;
    },
    saveAssesment(state, payload) {
      state[payload.key] = payload.data;
    },
    removeAssessment(state) {
      state.obstetrics_status = undefined;
      state.neonate_status = undefined;
      state.physical_condition = undefined;
      state.diagnose_and_therapy = undefined;
    },
  },
};
