import Constant from '@/const';

const _ = require('lodash');
const axios = require('axios');
const moment = require('moment');

function checkStringBlank(myString) {
  return /^\s*$/.test(myString);
}

export default {
  namespaced: true,
  state: {
    isAdvancedAssesment: false,
    nurseAssesment: {
      historyOfPregnancy: null,
      historyOfBirthControl: null,
      generalCondition: null,
      prayingAbility: null,
      socialStatusAndEconomy: null,
      nutritionScreening: null,
      functionalStatus: null,
      fallRiskAssesment: null,
      painAssesment: null,
    },
    doctorAssesment: {
      doctorAssesment: null,
      physicalCheckup: null,
    },
  },
  getters: {
    isAdvancedAssesment: (state) => state.isAdvancedAssesment,
    nurseAssesment: (state) => state.nurseAssesment,
    doctorAssesment: (state) => state.doctorAssesment,
  },
  mutations: {
    changeAssesment(state, payload) {
      state.isAdvancedAssesment = payload;
    },
    saveAssesment(state, payload) {
      if (payload.section === '') {
        state[payload.key] = payload.data;
      } else {
        state[payload.key][payload.section] = payload.data;
      }
    },
    removeAssessment(state) {
      state.nurseAssesment = {
        historyOfPregnancy: null,
        historyOfBirthControl: null,
        generalCondition: null,
        prayingAbility: null,
        socialStatusAndEconomy: null,
        nutritionScreening: null,
        functionalStatus: null,
        fallRiskAssesment: null,
        painAssesment: null,
      };
      state.doctorAssesment = {
        doctorAssesment: null,
        physicalCheckup: null,
      };
    },
  },
  actions: {
    postDiagnose({ getters, rootGetters }) {
      return axios.post(
        Constant.apiUrl
          .concat('/patient/action/diagnosis/3/2/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/1/'),
        {
          detail: [
            {
              diagnosis: checkStringBlank(
                getters.doctorAssesment.diagnoses[
                  getters.doctorAssesment.diagnoses.length - 1
                ],
              )
                ? getters.doctorAssesment.diagnoses.slice(
                    0,
                    getters.doctorAssesment.diagnoses.length - 1,
                  )
                : getters.doctorAssesment.diagnoses,
              timestamps: {
                created_by: rootGetters.userLoggedIn.id,
              },
            },
          ],
        },
      );
    },
    postNurseAssesment({ getters, rootGetters }) {
      let socialStatusAndEconomy;
      let nutritionScreening;
      if (
        getters.nurseAssesment.socialStatusAndEconomy &&
        getters.nurseAssesment.nutritionScreening
      ) {
        socialStatusAndEconomy = {
          marriage_status:
            getters.nurseAssesment.socialStatusAndEconomy.marriedStatus,
          family_relationship:
            getters.nurseAssesment.socialStatusAndEconomy.familyRelationship ===
            0,
          address: {
            detail:
              getters.nurseAssesment.socialStatusAndEconomy.residence.type === 2
                ? getters.nurseAssesment.socialStatusAndEconomy.residence.other
                : '',
            type: getters.nurseAssesment.socialStatusAndEconomy.residence.type,
          },
          job: getters.nurseAssesment.socialStatusAndEconomy.job,
        };
        nutritionScreening = {
          no1: getters.nurseAssesment.nutritionScreening.input.weightDrop,
          no2: getters.nurseAssesment.nutritionScreening.input.foodIntakeDrop,
          total_score: getters.nurseAssesment.nutritionScreening.totalScore,
        };
      } else {
        socialStatusAndEconomy = {
          marriage_status: 0,
          family_relationship: 0,
          address: {
            detail: '',
            type: 0,
          },
          job: '',
        };
        nutritionScreening = {
          no1: 0,
          no2: 0,
          total_score: 0,
        };
      }
      const postPayload = {
        detail_poly: [
          {
            flag: getters.isAdvancedAssesment,
            detail_nursery: [
              {
                // TODO: Change value acording to button
                amc: true,
                detail_pregnancy:
                  getters.nurseAssesment.historyOfPregnancy === null
                    ? []
                    : getters.nurseAssesment.historyOfPregnancy.map((data) => {
                        return {
                          pregnancy: data.childbirth,
                          gender: data.gender,
                          recourse: data.helper,
                          place: data.place,
                          life_status: data.isAlive,
                          bbl: Number(data.weight),
                          complications: data.complication,
                          information: data.detail,
                        };
                      }),
                drug_history: {
                  type: getters.nurseAssesment.historyOfBirthControl.drugs.type,
                  detail: checkStringBlank(
                    getters.nurseAssesment.historyOfBirthControl.drugs.detail[
                      getters.nurseAssesment.historyOfBirthControl.drugs.detail
                        .length - 1
                    ],
                  )
                    ? getters.nurseAssesment.historyOfBirthControl.drugs.detail.slice(
                        0,
                        getters.nurseAssesment.historyOfBirthControl.drugs
                          .detail.length - 1,
                      )
                    : getters.nurseAssesment.historyOfBirthControl.drugs.detail,
                  dosage: checkStringBlank(
                    getters.nurseAssesment.historyOfBirthControl.drugs.dose[
                      getters.nurseAssesment.historyOfBirthControl.drugs.dose
                        .length - 1
                    ],
                  )
                    ? getters.nurseAssesment.historyOfBirthControl.drugs.dose.slice(
                        0,
                        getters.nurseAssesment.historyOfBirthControl.drugs.dose
                          .length - 1,
                      )
                    : getters.nurseAssesment.historyOfBirthControl.drugs.dose,
                  timestamps: checkStringBlank(
                    getters.nurseAssesment.historyOfBirthControl.drugs
                      .lastUsage[
                      getters.nurseAssesment.historyOfBirthControl.drugs
                        .lastUsage.length - 1
                    ],
                  )
                    ? getters.nurseAssesment.historyOfBirthControl.drugs.lastUsage.slice(
                        0,
                        getters.nurseAssesment.historyOfBirthControl.drugs
                          .lastUsage.length - 1,
                      )
                    : getters.nurseAssesment.historyOfBirthControl.drugs
                        .lastUsage,
                },
                detail_family_plain: {
                  alergic_history: {
                    type: getters.nurseAssesment.historyOfBirthControl.allergies
                      .type,
                    detail:
                      // eslint-disable-next-line no-nested-ternary
                      getters.nurseAssesment.historyOfBirthControl.allergies
                        .type === 0
                        ? []
                        : checkStringBlank(
                            getters.nurseAssesment.historyOfBirthControl
                              .allergies.detail[
                              getters.nurseAssesment.historyOfBirthControl
                                .allergies.detail.length - 1
                            ],
                          )
                        ? getters.nurseAssesment.historyOfBirthControl.allergies.detail
                            .slice(
                              0,
                              getters.nurseAssesment.historyOfBirthControl
                                .allergies.detail.length - 1,
                            )
                            .map((data) => {
                              return {
                                type: data,
                              };
                            })
                        : getters.nurseAssesment.historyOfBirthControl.allergies.detail.map(
                            (data) => {
                              return {
                                type: data,
                              };
                            },
                          ),
                  },
                  family_plain_history:
                    getters.nurseAssesment.historyOfBirthControl === null
                      ? {
                          no1: '',
                          no2: '',
                          no3: '',
                          no4: '',
                          no5: '',
                          no6: '',
                          no7: '',
                          no8: '',
                          no9: '',
                        }
                      : {
                          no1: getters.nurseAssesment.historyOfBirthControl
                            .birthControl.isSyringe,
                          no2: getters.nurseAssesment.historyOfBirthControl
                            .birthControl.isIud,
                          no3: getters.nurseAssesment.historyOfBirthControl
                            .birthControl.isPill,
                          no4: getters.nurseAssesment.historyOfBirthControl
                            .birthControl.isCondom,
                          no5: getters.nurseAssesment.historyOfBirthControl
                            .birthControl.isCalendar,
                          no6: getters.nurseAssesment.historyOfBirthControl
                            .birthControl.isMow,
                          no7: getters.nurseAssesment.historyOfBirthControl
                            .birthControl.isMop,
                          no8: getters.nurseAssesment.historyOfBirthControl
                            .birthControl.isImplant,
                          no9: getters.nurseAssesment.historyOfBirthControl
                            .birthControl.noBirthControl,
                        },
                  menstruation_history: {
                    mencarche:
                      getters.nurseAssesment.historyOfBirthControl
                        .menstruation === 1,
                    cycle: Number(
                      getters.nurseAssesment.historyOfBirthControl.cycle,
                    ),
                    duration: Number(
                      getters.nurseAssesment.historyOfBirthControl
                        .cycleDuration,
                    ),
                  },
                  psychospiritual_history: {
                    type: getters.nurseAssesment.historyOfBirthControl
                      .psychospiritual.type,
                    detail: checkStringBlank(
                      getters.nurseAssesment.historyOfBirthControl
                        .psychospiritual.detail[
                        getters.nurseAssesment.historyOfBirthControl
                          .psychospiritual.detail.length - 1
                      ],
                    )
                      ? getters.nurseAssesment.historyOfBirthControl.psychospiritual.detail
                          .slice(
                            0,
                            getters.nurseAssesment.historyOfBirthControl
                              .psychospiritual.detail.length - 1,
                          )
                          .map((data) => {
                            return {
                              type: data,
                            };
                          })
                      : getters.nurseAssesment.historyOfBirthControl.psychospiritual.detail.map(
                          (data) => {
                            return {
                              type: data,
                            };
                          },
                        ),
                  },
                },
                tocology_study:
                  getters.nurseAssesment.historyOfBirthControl.mainComplaint,
                general_situation: {
                  blood_presure: [
                    Number(getters.nurseAssesment.generalCondition.sistol),
                    Number(getters.nurseAssesment.generalCondition.diastol),
                  ],
                  pluse_frequency: Number(
                    getters.nurseAssesment.generalCondition.pulse,
                  ),
                  breaty_frequency: Number(
                    getters.nurseAssesment.generalCondition.respiration,
                  ),
                  temperature: Number(
                    getters.nurseAssesment.generalCondition.temperature,
                  ),
                  weight_body: Number(
                    getters.nurseAssesment.generalCondition.weight,
                  ),
                  height_body: Number(
                    getters.nurseAssesment.generalCondition.height,
                  ),
                  lila: Number(getters.nurseAssesment.generalCondition.lila),
                },
                psychology:
                  getters.nurseAssesment.generalCondition
                    .psychologicalAssesment,
                praying_ability: {
                  no1: {
                    type: _.get(
                      getters,
                      'nurseAssesment.prayingAbility.obligatoryPrayer.type',
                      '',
                    ),
                    detail:
                      _.get(
                        getters,
                        'nurseAssesment.prayingAbility.obligatoryPrayer.type',
                        0,
                      ) === 2
                        ? _.get(
                            getters,
                            'nurseAssesment.prayingAbility.obligatoryPrayer.detail',
                            '',
                          )
                        : '',
                  },
                  no2: _.get(
                    getters,
                    'nurseAssesment.prayingAbility.wudhu',
                    '',
                  ),
                  no3: _.get(
                    getters,
                    'nurseAssesment.prayingAbility.sholat',
                    '',
                  ),
                },
                socio_economy: socialStatusAndEconomy,
                nutrition_screening: nutritionScreening,
                functional_status: {
                  tools_usage: _.get(
                    getters,
                    'nurseAssesment.functionalStatus.input.helperTools',
                    '',
                  ),
                  disability:
                    _.get(
                      getters,
                      'nurseAssesment.functionalStatus.input.disability',
                      0,
                    ) === 1,
                  disabilities:
                    _.get(
                      getters,
                      'nurseAssesment.functionalStatus.input.disability',
                      0,
                    ) === 1
                      ? _.get(
                          getters,
                          'nurseAssesment.functionalStatus.disabilities',
                          [],
                        )
                      : [],
                },
                risk_assessment_falls: {
                  age_type:
                    _.get(rootGetters, 'selectedPatient.meta.age', 0) <= 18
                      ? 1
                      : 0,
                  children: {
                    diagnosis: _.get(
                      getters,
                      'nurseAssesment.fallRiskAssesment.children.diagnosis',
                      0,
                    ),
                    cognitiveImpairment: _.get(
                      getters,
                      'nurseAssesment.fallRiskAssesment.children.cognitiveImpairment',
                      0,
                    ),
                    environmentFactor: _.get(
                      getters,
                      'nurseAssesment.fallRiskAssesment.children.environmentFactor',
                      0,
                    ),
                    responseToAnesthesia: _.get(
                      getters,
                      'nurseAssesment.fallRiskAssesment.children.responseToAnesthesia',
                      0,
                    ),
                    drugUsed: _.get(
                      getters,
                      'nurseAssesment.fallRiskAssesment.children.drugUsed',
                      0,
                    ),
                    detail:
                      _.get(
                        getters,
                        'nurseAssesment.fallRiskAssesment.children.diagnosis',
                        0,
                      ) === 1
                        ? _.get(
                            getters,
                            'nurseAssesment.fallRiskAssesment.children.detail',
                            '',
                          )
                        : '',
                  },
                  adult: {
                    isBalanced:
                      _.get(
                        getters,
                        'nurseAssesment.fallRiskAssesment.adult.isBalanced',
                        0,
                      ) === 1,
                    toolsHelper:
                      _.get(
                        getters,
                        'nurseAssesment.fallRiskAssesment.adult.toolsHelper',
                        0,
                      ) === 1,
                  },
                },
                pain_assesment: {
                  type:
                    _.get(
                      getters,
                      'nurseAssesment.painAssesment.input.pain',
                      0,
                    ) === 0,
                  detail: {
                    score: _.get(
                      getters,
                      'nurseAssesment.painAssesment.input.intensity.level',
                      '',
                    ),
                    provocation: _.get(
                      getters,
                      'nurseAssesment.painAssesment.input.provocation.value',
                      [],
                    )
                      .filter((data) => data.value)
                      .map((data) => ({
                        type:
                          data.index === 4
                            ? _.get(
                                getters,
                                'nurseAssesment.painAssesment.input.provocation.detail',
                                '',
                              )
                            : _.get(
                                getters,
                                `nurseAssesment.painAssesment.labels.provocation[${data.index}]`,
                                '',
                              ),
                      })),
                    Quality: _.get(
                      getters,
                      'nurseAssesment.painAssesment.input.quality.value',
                      [],
                    )
                      .filter((data) => data.value)
                      .map((data) => ({
                        type:
                          data.index === 8
                            ? _.get(
                                getters,
                                'nurseAssesment.painAssesment.input.quality.detail',
                                '',
                              )
                            : _.get(
                                getters,
                                `nurseAssesment.painAssesment.labels.quality[${data.index}]`,
                                '',
                              ),
                      })),
                    Radiation:
                      _.get(
                        getters,
                        'nurseAssesment.painAssesment.input.radiation',
                        0,
                      ) === 1,
                    Severity: _.get(
                      getters,
                      'nurseAssesment.painAssesment.input.severity',
                      '',
                    ),
                    Time: {
                      type:
                        _.get(
                          getters,
                          'nurseAssesment.painAssesment.input.time',
                          0,
                        ) === 1,
                      duration:
                        _.get(
                          getters,
                          'nurseAssesment.painAssesment.input.timeDuration',
                          0,
                        ) === 1,
                    },
                  },
                },
                tocology_diagnosis:
                  getters.nurseAssesment.painAssesment.input.diagnoses.map(
                    (data) => {
                      let plans = data.plans
                        .filter((pl) => pl || pl === 0)
                        .map((plan) => (plan.value ? plan.value : plan));
                      plans = plans.filter((a, i) => plans.indexOf(a) === i);
                      return {
                        data: data.detail,
                        plan: plans,
                        // .slice(0, item.plans.length - 1),
                      };
                    },
                  ),
                timestamps: {
                  created_by: rootGetters.userLoggedIn.id,
                },
              },
            ],
          },
        ],
      };
      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.detail_poly[0].detail_nursery[0] = {
          ...postPayload.detail_poly[0].detail_nursery[0],
          timestamps: {
            created_by:
              getters.nurseAssesment.historyOfBirthControl.timestamps
                .created_by,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }

      postPayload.detail_poly[0].detail_nursery[0].detail_pregnancy.forEach(
        (arr, index) => {
          if (arr.gender !== true && arr.gender !== false) {
            delete postPayload.detail_poly[0].detail_nursery[0]
              .detail_pregnancy[index].gender;
          }
        },
      );

      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/3/1/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/2/'),
        postPayload,
      );
    },
    postDoctorAssesment({ getters, rootGetters }) {
      console.log('getters doctor assesment :', getters.doctorAssesment);
      const tempStatus =
        +getters.doctorAssesment.physicalCheckup.babyPosition.value >= 9
          ? 'twin'
          : 'single';
      const postPayload = {
        detail_poly: [
          {
            detail_doctor: [
              {
                birth_type:
                  +getters.doctorAssesment.physicalCheckup.babyPosition.value >=
                  9
                    ? 'twin'
                    : 'single',
                anamnesis: getters.doctorAssesment.doctorAssesment.anamnesis,
                follow_up:
                  getters.doctorAssesment.physicalCheckup.follow_up.map(
                    (arr) => {
                      return arr.icd && arr.icd.id
                        ? {
                            _id: arr.icd.id,
                            value: arr.icd.value,
                            text: arr.icd.text,
                            detail: arr.detail,
                          }
                        : { detail: arr.detail };
                    },
                  ),
                return_status: {
                  type: getters.doctorAssesment.physicalCheckup.action,
                  // detail: getters.doctorAssesment.physicalCheckup.referral,
                  detail:
                    // eslint-disable-next-line no-nested-ternary
                    getters.doctorAssesment.physicalCheckup.action === 3
                      ? getters.doctorAssesment.physicalCheckup.internalReferral.filter(
                          (arr) => arr !== null,
                        )
                      : getters.doctorAssesment.physicalCheckup.action === 4
                      ? getters.doctorAssesment.physicalCheckup.referral.filter(
                          (arr) => arr !== null,
                        )
                      : [],
                },
                physical_checkup: {
                  palpasiLeopold:
                    getters.doctorAssesment.physicalCheckup.palpasiLeopold,
                  baby_position: {
                    text: getters.doctorAssesment.physicalCheckup.babyPosition
                      .text,
                    value:
                      getters.doctorAssesment.physicalCheckup.babyPosition
                        .value,
                    src: getters.doctorAssesment.physicalCheckup.babyPosition
                      .src,
                  },
                  placenta_position: {
                    text: getters.doctorAssesment.physicalCheckup
                      .placentaPosition.text,
                    value:
                      getters.doctorAssesment.physicalCheckup.placentaPosition
                        .value,
                    src: getters.doctorAssesment.physicalCheckup
                      .placentaPosition.src,
                  },
                  // auscultation: {
                  //   djj: getters.doctorAssesment.physicalCheckup.auscultation
                  //     .detail,
                  //   status:
                  //     getters.doctorAssesment.physicalCheckup.auscultation
                  //       .type === 0,
                  // },
                  placental_adhesion:
                    getters.doctorAssesment.physicalCheckup.placentaAttach, // perlekatan plasenta
                  gravida_supervis: getters.doctorAssesment.physicalCheckup.gs, // GS
                  amniositas:
                    getters.doctorAssesment.physicalCheckup.amniositicType, // amniositas,
                  amnioticamount:
                    getters.doctorAssesment.physicalCheckup.amnioticamount,
                  // another_checkup:
                  //   getters.doctorAssesment.physicalCheckup.otherCheckup,
                  // getters.doctorAssesment.physicalCheckup.otherCheckup === 1,
                  // patellar_reflex_extremity:
                  //   getters.doctorAssesment.physicalCheckup.extrimities,
                  // uodema: getters.doctorAssesment.physicalCheckup.uodema,
                },
                diagnostic:
                  getters.doctorAssesment.physicalCheckup.diagnosis.map(
                    (diagnose) => {
                      return diagnose.icd && diagnose.icd.id
                        ? {
                            _id: diagnose.icd.id,
                            value: diagnose.icd.value,
                            text: diagnose.icd.text,
                            detail: diagnose.detail,
                          }
                        : { detail: diagnose.detail };
                    },
                  ),
                isAnc: getters.doctorAssesment.physicalCheckup.isAnc,
                support_service_result:
                  getters.doctorAssesment.physicalCheckup
                    .support_service_result,
                action_plan: getters.doctorAssesment.physicalCheckup.plan,
                instruction:
                  getters.doctorAssesment.physicalCheckup.instruction,
                timestamps: {
                  created_by: rootGetters.userLoggedIn.id,
                },
              },
            ],
          },
        ],
      };
      if (
        getters.doctorAssesment.physicalCheckup.otherCheckup === 0 ||
        getters.doctorAssesment.physicalCheckup.otherCheckup === 1
      ) {
        postPayload.detail_poly[0].detail_doctor[0] = {
          ...postPayload.detail_poly[0].detail_doctor[0],
          physical_checkup: {
            ...postPayload.detail_poly[0].detail_doctor[0].physical_checkup,
            another_checkup:
              getters.doctorAssesment.physicalCheckup.otherCheckup === 1,
          },
        };
      }
      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.detail_poly[0].detail_doctor[0] = {
          ...postPayload.detail_poly[0].detail_doctor[0],
          timestamps: {
            created_by:
              getters.doctorAssesment.physicalCheckup.timestamps.created_by,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      if (tempStatus === 'twin') {
        postPayload.detail_poly[0].detail_doctor[0] = {
          ...postPayload.detail_poly[0].detail_doctor[0],
          physical_checkup: {
            ...postPayload.detail_poly[0].detail_doctor[0].physical_checkup,
            auscultation: {
              djj: getters.doctorAssesment.physicalCheckup.auscultationDouble
                .detail,
              status:
                getters.doctorAssesment.physicalCheckup.auscultationDouble.type.map(
                  (arr) => (arr === null ? null : arr !== 0),
                ),
            },
            tbj: getters.doctorAssesment.physicalCheckup.tbjDouble,
          },
        };
      } else {
        postPayload.detail_poly[0].detail_doctor[0] = {
          ...postPayload.detail_poly[0].detail_doctor[0],
          physical_checkup: {
            ...postPayload.detail_poly[0].detail_doctor[0].physical_checkup,
            auscultation: {
              djj: [
                getters.doctorAssesment.physicalCheckup.auscultation.detail,
              ],
              status: [
                getters.doctorAssesment.physicalCheckup.auscultation.type ===
                null
                  ? null
                  : getters.doctorAssesment.physicalCheckup.auscultation
                      .type === 0,
              ],
            },
            tbj: getters.doctorAssesment.physicalCheckup.tbj
              ? [getters.doctorAssesment.physicalCheckup.tbj]
              : [],
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/3/1/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/1/'),
        postPayload,
      );
    },
  },
};
