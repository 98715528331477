import Constant from '@/const';

const _ = require('lodash');
const axios = require('axios');
const moment = require('moment');

function checkStringBlank(myString) {
  return typeof myString === 'string'
    ? /^\s*$/.test(myString)
    : myString === undefined;
}

export default {
  namespaced: true,
  state: {
    isAdvancedAssesment: false,
    eyeRecipe: null,
    nurseAssesment: {
      subjectiveData: null,
      objectiveData: null,
      functionalStatus: null,
      fallRiskAssesment: null,
      painAssesment: null,
      nutritionScreening: null,
      prayingAbility: null,
    },
    doctorAssesment: null,
  },
  getters: {
    eyeRecipe: (state) => state.eyeRecipe,
    isAdvancedAssesment: (state) => state.isAdvancedAssesment,
    nurseAssesment: (state) => state.nurseAssesment,
    doctorAssesment: (state) => state.doctorAssesment,
  },
  mutations: {
    changeAssesment(state, payload) {
      state.isAdvancedAssesment = payload;
    },
    saveAssesment(state, payload) {
      if (payload.section === '') {
        state[payload.key] = payload.data;
      } else {
        state[payload.key][payload.section] = payload.data;
      }
    },
    removeAssessment(state) {
      state.nurseAssesment = {
        subjectiveData: null,
        objectiveData: null,
        functionalStatus: null,
        fallRiskAssesment: null,
        painAssesment: null,
        nutritionScreening: null,
        prayingAbility: null,
      };
      state.doctorAssesment = null;
      state.eyeRecipe = null;
    },
  },
  actions: {
    postEyeRecipe({ getters, rootGetters }) {
      const postPayload = {
        detail_poly: [
          {
            detail_receipt: [
              {
                to_usage: getters.eyeRecipe.opticsPurpose,
                left_eye: {
                  type: getters.eyeRecipe.leftEye.fill,
                  sph: {
                    D: getters.eyeRecipe.leftEye.sph,
                  },
                  cylinder: {
                    D: getters.eyeRecipe.leftEye.cyclinderD,
                    AS: getters.eyeRecipe.leftEye.cyclinderAs,
                  },
                  prisma: {
                    gr: getters.eyeRecipe.leftEye.prismaGr,
                    bas: getters.eyeRecipe.leftEye.prismaBas,
                  },
                },
                right_eye: {
                  type: getters.eyeRecipe.rightEye.fill,
                  sph: {
                    D: getters.eyeRecipe.rightEye.sph,
                  },
                  cylinder: {
                    D: getters.eyeRecipe.rightEye.cyclinderD,
                    AS: getters.eyeRecipe.rightEye.cyclinderAs,
                  },
                  prisma: {
                    gr: getters.eyeRecipe.rightEye.prismaGr,
                    bas: getters.eyeRecipe.rightEye.prismaBas,
                  },
                },
                popularis: getters.eyeRecipe.popularis,
                to_usage_popularis: getters.eyeRecipe.additionalPurpose,
                timestamps: {
                  created_by: rootGetters.userLoggedIn.id,
                },
              },
            ],
          },
        ],
      };

      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/5/2/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/2'),
        postPayload,
      );
    },
    postNurseAssesment({ getters, rootGetters }) {
      const lastDisabilityIndex =
        _.get(
          getters,
          'nurseAssesment.functionalStatus.disabilities.length',
          0,
        ) - 1;
      const lastDisability = _.get(
        getters,
        `nurseAssesment.functionalStatus.disabilities[${lastDisabilityIndex}]`,
        '',
      );
      let nutritionScreening;
      let functionalStatus;
      if (getters.isAdvancedAssesment === false) {
        nutritionScreening = {
          no1: _.get(
            getters,
            'nurseAssesment.nutritionScreening.input.weightDrop',
          ),
          no2: _.get(
            getters,
            'nurseAssesment.nutritionScreening.input.foodIntakeDrop',
          ),
        };
        functionalStatus = {
          tools_usage: _.get(
            getters,
            'nurseAssesment.functionalStatus.input.helperTools',
            '',
          ),
          disability:
            _.get(
              getters,
              'nurseAssesment.functionalStatus.input.disability',
              0,
            ) === 1,
          disabilities: checkStringBlank(lastDisability)
            ? _.slice(
                _.get(
                  getters,
                  'nurseAssesment.functionalStatus.disabilities',
                  [],
                ),
                0,
                lastDisabilityIndex,
              )
            : _.get(
                getters,
                'nurseAssesment.functionalStatus.disabilities',
                [],
              ),
        };
      } else {
        nutritionScreening = {
          no1: 0,
          no2: 0,
        };
        functionalStatus = {
          tools_usage: 3,
          disability: 0,
          disabilities: [],
        };
      }
      const nurseplan = [];
      getters.nurseAssesment.prayingAbility.diagnoses.forEach((diagnose) => {
        diagnose.plans.forEach((plan) => {
          if (plan.id_plan) nurseplan.push(plan.id_plan);
        });
      });
      const postPayload = {
        detail_poly: [
          {
            flag_assesment: getters.isAdvancedAssesment,
            detail_nursery: [
              {
                subjective: {
                  type_of_disease:
                    getters.nurseAssesment.subjectiveData.typeOfDisease,
                  primary_complaint:
                    getters.nurseAssesment.subjectiveData.description,
                  deasease_history: checkStringBlank(
                    getters.nurseAssesment.subjectiveData.sickness[
                      getters.nurseAssesment.subjectiveData.sickness.length - 1
                    ],
                  )
                    ? getters.nurseAssesment.subjectiveData.sickness.slice(
                        0,
                        getters.nurseAssesment.subjectiveData.sickness.length -
                          1,
                      )
                    : getters.nurseAssesment.subjectiveData.sickness,
                  /*
                    family_sickness: checkStringBlank(getters.nurseAssesment.subjectiveData.familySickness.join('')[getters.nurseAssesment.subjectiveData.familySickness.join('').length - 1])
                    ? getters.nurseAssesment.subjectiveData.familySickness.join('').slice(0, getters.nurseAssesment.subjectiveData.familySickness.length-1)
                    : getters.nurseAssesment.subjectiveData.familySickness.join(''),
                    */
                  alergic_history: {
                    type:
                      getters.nurseAssesment.subjectiveData.allergyHistory === 0
                        ? 1
                        : 0,
                    detail:
                      // eslint-disable-next-line no-nested-ternary
                      getters.nurseAssesment.subjectiveData.allergyHistory === 0
                        ? []
                        : checkStringBlank(
                            getters.nurseAssesment.subjectiveData.allergies[
                              getters.nurseAssesment.subjectiveData.allergies
                                .length - 1
                            ],
                          )
                        ? getters.nurseAssesment.subjectiveData.allergies
                            .slice(
                              0,
                              getters.nurseAssesment.subjectiveData.allergies
                                .length - 1,
                            )
                            .map((data) => {
                              return {
                                type: data,
                              };
                            })
                        : getters.nurseAssesment.subjectiveData.allergies.map(
                            (data) => {
                              return {
                                type: data,
                              };
                            },
                          ),
                  },
                  drug_history: {
                    type: getters.nurseAssesment.subjectiveData.drugs.type,
                    detail: checkStringBlank(
                      getters.nurseAssesment.subjectiveData.drugs.detail[
                        getters.nurseAssesment.subjectiveData.drugs.detail
                          .length - 1
                      ],
                    )
                      ? getters.nurseAssesment.subjectiveData.drugs.detail.slice(
                          0,
                          getters.nurseAssesment.subjectiveData.drugs.detail
                            .length - 1,
                        )
                      : getters.nurseAssesment.subjectiveData.drugs.detail,
                    dosage: checkStringBlank(
                      getters.nurseAssesment.subjectiveData.drugs.dose[
                        getters.nurseAssesment.subjectiveData.drugs.dose
                          .length - 1
                      ],
                    )
                      ? getters.nurseAssesment.subjectiveData.drugs.dose.slice(
                          0,
                          getters.nurseAssesment.subjectiveData.drugs.dose
                            .length - 1,
                        )
                      : getters.nurseAssesment.subjectiveData.drugs.dose,
                    timestamps: checkStringBlank(
                      getters.nurseAssesment.subjectiveData.drugs.lastUsage[
                        getters.nurseAssesment.subjectiveData.drugs.lastUsage
                          .length - 1
                      ],
                    )
                      ? getters.nurseAssesment.subjectiveData.drugs.lastUsage.slice(
                          0,
                          getters.nurseAssesment.subjectiveData.drugs.lastUsage
                            .length - 1,
                        )
                      : getters.nurseAssesment.subjectiveData.drugs.lastUsage,
                  },
                  psychospiritual_history: {
                    type: getters.nurseAssesment.subjectiveData
                      .psychospiritualHistory.type,
                    detail:
                      getters.nurseAssesment.subjectiveData
                        .psychospiritualHistory.type === 0
                        ? []
                        : getters.nurseAssesment.subjectiveData.psychospiritualHistory.value.map(
                            (data) => {
                              return {
                                type: data,
                              };
                            },
                          ),
                  },
                },
                objective: {
                  functional_status: functionalStatus,
                  general_condition:
                    getters.nurseAssesment.objectiveData.generalCondition,
                  ttv: {
                    blood_presure: [
                      getters.nurseAssesment.objectiveData.sistol,
                      getters.nurseAssesment.objectiveData.diastol,
                    ],
                    temperature: getters.nurseAssesment.objectiveData.ttv[1],
                    pulse: getters.nurseAssesment.objectiveData.ttv[2],
                    RR: getters.nurseAssesment.objectiveData.ttv[3],
                    saturation: getters.nurseAssesment.objectiveData.ttv[4],
                    height: getters.nurseAssesment.objectiveData.ttv[5],
                    weight: getters.nurseAssesment.objectiveData.ttv[6],
                  },
                  risk_assessment_falls: {
                    age_type:
                      rootGetters.selectedPatient.meta.age <= 18 ? 1 : 0,
                    children: {
                      diagnosis: _.get(
                        getters,
                        'nurseAssesment.fallRiskAssesment.children.diagnosis',
                        0,
                      ),
                      cognitiveImpairment: _.get(
                        getters,
                        'nurseAssesment.fallRiskAssesment.children.cognitiveImpairment',
                        0,
                      ),
                      environmentFactor: _.get(
                        getters,
                        'nurseAssesment.fallRiskAssesment.children.environmentFactor',
                        0,
                      ),
                      responseToAnesthesia: _.get(
                        getters,
                        'nurseAssesment.fallRiskAssesment.children.responseToAnesthesia',
                        0,
                      ),
                      drugUsed: _.get(
                        getters,
                        'nurseAssesment.fallRiskAssesment.children.drugUsed',
                        0,
                      ),
                      detail:
                        _.get(
                          getters,
                          'nurseAssesment.fallRiskAssesment.children.diagnosis',
                          0,
                        ) === 1
                          ? _.get(
                              getters,
                              'nurseAssesment.fallRiskAssesment.children.detail',
                              '',
                            )
                          : '',
                    },
                    adult: {
                      isBalanced:
                        _.get(
                          getters,
                          'nurseAssesment.fallRiskAssesment.adult.isBalanced',
                          0,
                        ) === 1,
                      toolsHelper:
                        _.get(
                          getters,
                          'nurseAssesment.fallRiskAssesment.adult.toolsHelper',
                          0,
                        ) === 1,
                    },
                  },
                  pain_assesment: {
                    type:
                      _.get(
                        getters,
                        'nurseAssesment.painAssesment.input.pain',
                        0,
                      ) === 0,
                    detail: {
                      score: _.get(
                        getters,
                        'nurseAssesment.painAssesment.input.intensity.level',
                        '',
                      ),
                      provocation: _.get(
                        getters,
                        'nurseAssesment.painAssesment.input.provocation.value',
                        [],
                      )
                        .filter((data) => data)
                        .map((data, index) => ({ index, value: data }))
                        .map((data) => ({
                          type:
                            data.index === 4
                              ? _.get(
                                  getters,
                                  'nurseAssesment.painAssesment.input.provocation.detail',
                                  '',
                                )
                              : _.get(
                                  getters,
                                  `nurseAssesment.painAssesment.labels.provocation[${data.index}]`,
                                  '',
                                ),
                        })),
                      quality: _.get(
                        getters,
                        'nurseAssesment.painAssesment.input.quality.value',
                        [],
                      )
                        .filter((data) => data)
                        .map((data, index) => ({ index, value: data }))
                        .map((data) => ({
                          type:
                            data.index === 8
                              ? _.get(
                                  getters,
                                  'nurseAssesment.painAssesment.input.quality.detail',
                                  '',
                                )
                              : _.get(
                                  getters,
                                  `nurseAssesment.painAssesment.labels.quality[${data.index}]`,
                                  '',
                                ),
                        })),
                      radiation:
                        _.get(
                          getters,
                          'nurseAssesment.painAssesment.input.radiation',
                          0,
                        ) === 1,
                      severity: _.get(
                        getters,
                        'nurseAssesment.painAssesment.input.severity',
                        '',
                      ),
                      time: {
                        type:
                          _.get(
                            getters,
                            'nurseAssesment.painAssesment.input.time',
                            0,
                          ) === 1,
                        duration:
                          _.get(
                            getters,
                            'nurseAssesment.painAssesment.input.timeDuration',
                            0,
                          ) === 1,
                      },
                    },
                  },
                  nutrition_screening: nutritionScreening,
                  praying_ability: {
                    no1: {
                      type: _.get(
                        getters,
                        'nurseAssesment.prayingAbility.obligatoryPrayer.type',
                        '',
                      ),
                      detail:
                        _.get(
                          getters,
                          'nurseAssesment.prayingAbility.obligatoryPrayer.type',
                          0,
                        ) === 2
                          ? _.get(
                              getters,
                              'nurseAssesment.prayingAbility.obligatoryPrayer.detail',
                              '',
                            )
                          : '',
                    },
                    no2: _.get(
                      getters,
                      'nurseAssesment.prayingAbility.wudhu',
                      '',
                    ),
                    no3: _.get(
                      getters,
                      'nurseAssesment.prayingAbility.sholat',
                      '',
                    ),
                  },
                  psychology: _.get(
                    getters,
                    'nurseAssesment.prayingAbility.psychologist',
                  ),
                  socio_economic: _.get(
                    getters,
                    'nurseAssesment.prayingAbility.socialEconomy',
                  ),
                  // nursery_diagnosis:
                  //   getters.nurseAssesment.prayingAbility.diagnoses.map(
                  //     (item) => {
                  //       let plans = item.plans
                  //         .filter((pl) => pl || pl === 0)
                  //         .map((plan) => (plan.value ? plan.value : plan));
                  //       plans = plans.filter((a, i) => plans.indexOf(a) === i);
                  //       return {
                  //         data: item.detail,
                  //         plan: plans,
                  //         // .slice(0, item.plans.length - 1),
                  //       };
                  //     },
                  //   ),
                  nurseplan,
                },
                timestamps: {
                  created_by: rootGetters.userLoggedIn.id,
                },
              },
            ],
          },
        ],
      };
      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.detail_poly[0].detail_nursery[0] = {
          ...postPayload.detail_poly[0].detail_nursery[0],
          timestamps: {
            created_by:
              getters.nurseAssesment.subjectiveData.timestamps.created_by,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/5/1/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/2/'),
        postPayload,
      );
    },
    postDoctorAssesment({ getters, rootGetters }) {
      const postPayload = {
        detail_poly: [
          {
            detail_doctor: [
              {
                anamnesis: getters.doctorAssesment.anamnesis.filter((a) => a),
                instruction: getters.doctorAssesment.instruction,
                physical_checkup: {
                  visus: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[0])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[0],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[0],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[0],
                  },
                  eyes: {
                    dextra: getters.doctorAssesment.dextraCoordinates.map(
                      (item) => {
                        return {
                          x: item.x,
                          y: item.y,
                        };
                      },
                    ),
                    sinistra: getters.doctorAssesment.sinistraCoordinates.map(
                      (item) => {
                        return {
                          x: item.x,
                          y: item.y,
                        };
                      },
                    ),
                  },
                  special_checkup: getters.doctorAssesment.specialCheckup,
                  follow_up: getters.doctorAssesment.follow_up
                    .filter((a) => a.detail || a.icd)
                    .map((arr) => {
                      return arr.icd && arr.icd.id
                        ? {
                            id: arr.icd.id,
                            value: arr.icd.value,
                            text: arr.icd.text,
                            detail: arr.detail,
                          }
                        : { detail: arr.detail };
                    }),
                  return_status: {
                    type: getters.doctorAssesment.action,
                    detail:
                      getters.doctorAssesment.action === 3
                        ? getters.doctorAssesment.internalReferral
                        : getters.doctorAssesment.referral,
                  },
                  subjective_refraction: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[1])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[1],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[1],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[1],
                  },
                  objective_refraction: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[2])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[2],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[2],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[2],
                  },
                  light_projection: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[3])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[3],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[3],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[3],
                  },
                  bulbus_okuli: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[4])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[4],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[4],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[4],
                  },
                  palpebra: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[5])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[5],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[5],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[5],
                  },
                  palpebra_conjunctive: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[6])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[6],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[6],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[6],
                  },
                  bulbi_conjunctive: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[7])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[7],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[7],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[7],
                  },
                  sclera: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[8])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[8],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[8],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[8],
                  },
                  cornea: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[9])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[9],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[9],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[9],
                  },
                  camera_oculi_anterior: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[10])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[10],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[10],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[10],
                  },
                  iris: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[11])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[11],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[11],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[11],
                  },
                  pupil: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[12])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[12],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[12],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[12],
                  },
                  lens: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[13])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[13],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[13],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[13],
                  },
                  fundus: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[14])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[14],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[14],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[14],
                  },
                  media: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[15])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[15],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[15],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[15],
                  },
                  papil: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[16])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[16],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[16],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[16],
                  },
                  makula: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[17])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[17],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[17],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[17],
                  },
                  retina: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[18])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[18],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[18],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[18],
                  },
                  tensio_oculi: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[19])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[19],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[19],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[19],
                  },
                  canalis_lacrimalis: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[20])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[20],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[20],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[20],
                  },
                  etc: {
                    dextra: checkStringBlank(getters.doctorAssesment.dextra[21])
                      ? 'Normal'
                      : getters.doctorAssesment.dextra[21],
                    sinistra: checkStringBlank(
                      getters.doctorAssesment.sinistra[21],
                    )
                      ? 'Normal'
                      : getters.doctorAssesment.sinistra[21],
                  },
                },
                eyes: {
                  dextra: getters.doctorAssesment.dextraCoordinates.map(
                    (item) => {
                      return {
                        x: item.x,
                        y: item.y,
                      };
                    },
                  ),
                  sinistra: getters.doctorAssesment.sinistraCoordinates.map(
                    (item) => {
                      return {
                        x: item.x,
                        y: item.y,
                      };
                    },
                  ),
                },
                special_checkup: getters.doctorAssesment.specialCheckup,
                follow_up: getters.doctorAssesment.follow_up
                  .filter((a) => a.detail || a.icd)
                  .map((arr) => {
                    return arr.icd && arr.icd.id
                      ? {
                          id: arr.icd.id,
                          value: arr.icd.value,
                          text: arr.icd.text,
                          detail: arr.detail,
                        }
                      : { detail: arr.detail };
                  }),
                return_status: {
                  type: getters.doctorAssesment.action,
                  detail:
                    getters.doctorAssesment.action === 3
                      ? getters.doctorAssesment.internalReferral
                      : getters.doctorAssesment.referral,
                },
                action_plan: getters.doctorAssesment.plan,
                work_diagnosis: getters.doctorAssesment.workDiagnosis
                  .filter((a) => a.detail || a.icd)
                  .map((diagnose) => {
                    return diagnose.icd && diagnose.icd.id
                      ? {
                          _id: diagnose.icd.id,
                          value: diagnose.icd.value,
                          text: diagnose.icd.text,
                          detail: diagnose.detail,
                        }
                      : { detail: diagnose.detail };
                  }),
                timestamps: {
                  created_by: rootGetters.userLoggedIn.id,
                },
              },
            ],
          },
        ],
      };
      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.detail_poly[0].detail_doctor[0] = {
          ...postPayload.detail_poly[0].detail_doctor[0],
          timestamps: {
            created_by: getters.doctorAssesment.timestamps.created_by,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/5/1/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/1/'),
        postPayload,
      );
    },
  },
};
