import Constant from '@/const';

const axios = require('axios');

export default {
  namespaced: true,
  state: {
    sepData: null,
    generalConsentData: null,
  },
  getters: {
    sepData: state => state.sepData,
    generalConsentData: state => state.generalConsentData,
  },
  mutations: {
    saveGeneralConsentData(state, payload) {
      state.generalConsentData = payload.data;
    },
    saveSepData(state, payload) {
      state.sepData = payload.data;
    },
    removeSepData(state) {
      state.sepData = null;
    },
    removeGeneralConsentData(state) {
      state.generalConsentData = null;
    },
  },
  actions: {
    postGeneralConsent({ getters, rootGetters }) {
      const statusPatient = getters.generalConsentData.patient_status;
      const patientId =
        rootGetters.selectedPatient.refference && statusPatient === 'jalan'
          ? rootGetters.selectedPatient.id_registration
          : rootGetters.selectedPatient.id_queue;
      return axios.post(
        Constant.apiUrl
          .concat('/inpatient/ijarah/generalconcent/')
          .concat(rootGetters.selectedPatient.id)
          .concat('/')
          .concat(patientId),
        {
          detail_registration: [
            {
              detail_information: [
                {
                  patient_status: getters.generalConsentData.patient_status,
                  signer: getters.generalConsentData.signedBy,
                  name: getters.generalConsentData.name,
                  address: getters.generalConsentData.address,
                  telephone_number: getters.generalConsentData.phone,
                  identity_number: getters.generalConsentData.identityNumber,
                  privacy: {
                    visit_permission:
                      getters.generalConsentData.allowanceToVisit.permission,
                    detail_profile: getters.generalConsentData.allowanceToVisit.name.map(
                      data => {
                        return {
                          profile: {
                            name: data,
                            kinship: '',
                          },
                        };
                      },
                    ),
                  },
                  information_discharge: {
                    discharge:
                      getters.generalConsentData.knowledgeOtherPeople
                        .permission,
                    detail_profile: getters.generalConsentData.knowledgeOtherPeople.personel.map(
                      data => {
                        return {
                          profile: {
                            name: data.name,
                            kinship: data.status,
                          },
                        };
                      },
                    ),
                  },
                },
              ],
            },
          ],
        },
      );
    },
    postSepData({ getters, rootGetters }) {
      return axios.post(
        Constant.apiUrl
          .concat('/inpatient/ijarah/sepletter/')
          .concat(rootGetters.selectedPatient.id)
          .concat('/')
          .concat(rootGetters.selectedPatient.id_queue),
        {
          detail_registration: [
            {
              detail_information: [
                {
                  sep_date: getters.sepData.sepDate,
                  origin_of_reference: getters.sepData.bpjsFacility,
                  ppk_origin: {
                    nomor: getters.sepData.originPpk.value,
                    detail: getters.sepData.originPpk.text,
                  },
                  card_number: getters.sepData.cardNo,
                  ppk_reference: getters.sepData.consultPpk,
                  reference_number: getters.sepData.consultNumber,
                  sprs_number: getters.sepData.spriNumber,
                  phone_number: getters.sepData.phone,
                  dpjp_skdp: {
                    nomor: getters.sepData.dpjp.value,
                    detail: getters.sepData.dpjp.text,
                  },
                  no_rm: rootGetters.selectedPatient.no_rm,
                  cob: getters.sepData.patientCob,
                  cataract: getters.sepData.catharact,
                  notes: getters.sepData.note,
                  diagnosis: {
                    nomor: getters.sepData.diagnose.value,
                    detail: getters.sepData.diagnose.text,
                    id: getters.sepData.diagnose.id,
                  },
                  accident_status: {
                    nomor: getters.sepData.accidentStatus.value,
                    detail: getters.sepData.accidentStatus.text,
                  },
                  nursing_class: {
                    nomor: getters.sepData.inpatientCareClass.value,
                    detail: getters.sepData.inpatientCareClass.text,
                  },
                  accident_date: getters.sepData.accidentDate,
                  accident_location: getters.sepData.accidentLocation,
                  accident_notes: getters.sepData.accidentInfo,
                  timestamps: {
                    created_by: rootGetters.userLoggedIn.id,
                  },
                },
              ],
            },
          ],
        },
      );
    },
  },
};
