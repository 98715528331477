// import Constant from '@/const';

// const axios = require('axios')

export default {
  namespaced: true,
  state: {
    supplier_id:null,
    dataSupplier:null
  },
  getters: {
    supplierId: (state)=> state.supplier_id,

  },
  mutations: {
    getSupplierId(state,payload){
      state.supplier_id = payload
    }

  },
};
