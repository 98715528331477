import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChartPie,
  faNotesMedical,
  faClipboardList,
  faBriefcaseMedical,
  faFileInvoiceDollar,
  faBoxOpen,
  faPrescriptionBottle,
  faUser,
  faLock,
  faChartBar,
  faUserMd,
  faClipboardCheck,
  faFolderPlus,
  faPlus,
  faTimes,
  faSave,
  faSyringe,
  faFlask,
  faSyncAlt,
  faCheck,
  faPrint,
  faFingerprint,
  faTrash,
  faChevronRight,
  faChevronLeft,
  faTasks,
  faReceipt,
  faChevronUp,
  faChevronDown,
  faFilter,
  faLifeRing,
  faEllipsisV,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faGlasses,
  faUserPlus,
  faFileSignature,
  faBed,
  faUtensils,
  faCalendarAlt,
  faExclamationCircle,
  faHandHoldingHeart,
  faHeartbeat,
  faCalendarPlus,
  faFile,
  faWindowClose,
  faPlusCircle,
  faHome,
  faUserNurse,
  faIdBadge,
  faRadiationAlt,
  faFutbol,
  faFileImage,
  faFileWord,
  faFilePdf,
  faFileCode,
  faSquare,
  faFileInvoice,
  faConciergeBell,
  faEdit,
  faThList,
  faVial,
  faXRay,
  faCloudUploadAlt,
  faArrowDown,
  faSpinner,
  faShieldAlt,
  faPlusSquare,
  faWheelchair,
  faMapMarked,
  faInfoCircle,
  faFileExcel,
  faDownload,
  faMinus,
  faInfo,
  faLungs,
  faCheckCircle,
  // faInfoCircle,
  // faFileExcel,
  // faDownload,
  faPrescription,
  faCreditCard,
  faTimesCircle,
  faCalendar,
  faCalendarDay,
  faFileAlt,
} from '@fortawesome/free-solid-svg-icons';
// import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
// eslint-disable-next-line no-unused-vars
import Swal from 'sweetalert2';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import Vuetify from 'vuetify';
import VueScrollTo from 'vue-scrollto';
import VueKonva from 'vue-konva';
// import VueTabs from 'vue-nav-tabs';
import VueSocketIOExt from 'vue-socket.io-extended';
import VCurrencyField from 'v-currency-field';
import Treeselect from '@riophae/vue-treeselect';
import { io } from 'socket.io-client';
import Constant from '@/const';
import App from './App.vue';
import store from './store';
import router from './router';
import './registerServiceWorker';
import 'vuetify/dist/vuetify.min.css';
import './assets/scss/main.scss';
import MultiFiltersPlugin from './plugins/MultiFilters';
import 'vue-nav-tabs/themes/vue-tabs.css';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import 'typeface-roboto/index.css';
import VueZoomer from 'vue-zoomer';
import VueApexCharts from 'vue-apexcharts';
import 'material-icons/iconfont/outlined.css';
import 'material-symbols/outlined.css';
import ToggleButton from 'vue-js-toggle-button';
import Vue2Editor from 'vue2-editor';
import VueMeta from 'vue-meta';
import './helpers/listenerServiceWorker';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import VueVirtualScroller from 'vue-virtual-scroller';
import VueSkeletonLoader from 'skeleton-loader-vue';

library.add(
  faGlasses,
  faChartPie,
  faChartBar,
  faNotesMedical,
  faClipboardList,
  faClipboardCheck,
  faNotesMedical,
  faBriefcaseMedical,
  faFileInvoiceDollar,
  faBoxOpen,
  faSave,
  faPrescriptionBottle,
  faUser,
  faUserMd,
  faLock,
  faFolderPlus,
  faPlus,
  faTimes,
  faCheck,
  faPrint,
  faSyringe,
  faFlask,
  faRadiationAlt,
  faSyncAlt,
  faTrash,
  faTasks,
  faCalendar,
  faLungs,
  faClipboardCheck,
  faFingerprint,
  faChevronRight,
  faChevronLeft,
  faReceipt,
  faChevronUp,
  faChevronDown,
  faFilter,
  faEllipsisV,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faUserPlus,
  faFileSignature,
  faBed,
  faUtensils,
  faCalendarAlt,
  faCalendarDay,
  faExclamationCircle,
  faHandHoldingHeart,
  faHeartbeat,
  faCalendarPlus,
  faFile,
  faFileAlt,
  faLifeRing,
  faWindowClose,
  faPlusCircle,
  faFilter,
  faHome,
  faFileInvoice,
  faUserNurse,
  faIdBadge,
  faHome,
  faUserNurse,
  faIdBadge,
  faFileInvoice,
  faFutbol,
  faFileExcel,
  faFileImage,
  faFileWord,
  faFilePdf,
  faFileCode,
  faFileSignature,
  faBed,
  faSquare,
  faConciergeBell,
  faUtensils,
  faEdit,
  faHome,
  faUserNurse,
  faHome,
  faEdit,
  faThList,
  faVial,
  faXRay,
  faCloudUploadAlt,
  faArrowDown,
  faSpinner,
  faPlusSquare,
  faShieldAlt,
  faWheelchair,
  faMapMarked,
  faInfoCircle,
  faDownload,
  faMinus,
  faInfo,
  faCheckCircle,
  faInfoCircle,
  faFileExcel,
  faDownload,
  faPrescription,
  faCreditCard,
  faTimesCircle,
);
Vue.use(VCurrencyField, {
  locale: 'id-ID',
  decimalLength: 2,
  autoDecimalMode: true,
  min: 0,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
});
Vue.use(
  VueSocketIOExt,
  io(Constant.apiUrl, {
    transports: ['websocket'],
    autoConnect: false,
  }),
  { store },
);
Vue.use(Vuetify, {
  iconFont: 'md',
});

Vue.use(VueMeta);
Vue.use(Vue2Editor);
Vue.use(ToggleButton);
Vue.use(VueKonva);
Vue.use(VueScrollTo);
Vue.use(VueZoomer);
Vue.use(VueApexCharts);
Vue.use(VueVirtualScroller);
// Vue.use(VueTabs);
Vue.component('treeselect', Treeselect);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('apexchart', VueApexCharts);
Vue.component('vue-skeleton-loader', VueSkeletonLoader);

Vue.config.productionTip = false;

// Vue.directive('click-outside', {
//   bind(el, binding, vnode) {
//     const element = el;
//     element.clickOutsideEvent = event => {
//       if (!(el === event.target || el.contains(event.target))) {
//         vnode.context[binding.expression](event);
//       }
//     };
//     document.body.addEventListener('click', element.clickOutsideEvent);
//   },

//   unbind(element) {
//     document.body.removeEventListener('click', element.clickOutsideEvent);
//   },
// });

router.onError((error) => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    localStorage.setItem('updateAvailable', true);
  }
});

Vue.use(MultiFiltersPlugin);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
