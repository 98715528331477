import Constant from '@/const';

const axios = require('axios');

export default {
  namespaced: true,
  state: {
    actions: [],
  },
  getters: {
    actions: state => state.actions,
  },
  mutations: {
    saveActions(state, payload) {
      state.actions = payload.actions;
    },
    removeActions(state) {
      state.actions = [];
    },
  },
  actions: {
    postActions({ getters, rootGetters }) {
      return axios.post(
        Constant.apiUrl.concat('/patient/action/').concat(rootGetters.selectedPatient.id_queue),
        {
          detail: [
            {
              detail: [
                {
                  action: getters.actions.map(action => {
                    return {
                      id_action: action.id,
                      total_doing: action.qty,
                      text: action.text,
                    };
                  }),
                  timestamps: {
                    added_by: rootGetters.userLoggedIn.id,
                  },
                },
              ],
            },
          ],
        },
      );
    },
  },
};
