import Constant from '@/const';

const axios = require('axios');

export default {
  namespaced: true,
  state: {
    drugs: [],
  },
  getters: {
    drugs: state => state.drugs,
  },
  mutations: {
    saveDrugs(state, payload) {
      state.drugs = payload.drugs;
    },
    removeDrugs(state) {
      state.drugs = [];
    },
  },
  actions: {
    postERecipe({ getters, rootGetters }) {
      return axios.post(
        Constant.apiUrl
          .concat('/patient/drug/receipt/')
          .concat(rootGetters.selectedPatient.id_queue),
        {
          R: [
            {
              detail_doctor: [
                {
                  detail_drug: getters.drugs.slice(0, getters.drugs.length - 1).map(drug => {
                    return {
                      item: drug.detail.id,
                      strength: drug.dose.detail,
                      racic_dose: drug.dose.unit,
                      usage1: drug.usage.dose,
                      usage2: drug.usage.per,
                      rules: drug.usage.detail,
                      request: drug.qty,
                      timestamps: {
                        created_by: rootGetters.userLoggedIn.id,
                      },
                    };
                  }),
                },
              ],
            },
          ],
        },
      );
    },
  },
};
