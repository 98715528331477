export default {
  namespaced: true,
  state: {
    patient_data: undefined,
    social_and_psychosocial: undefined,
    physical_examination: undefined,
    labor_records: undefined,
    files: undefined,
  },
  getters: {
    assessmentData: (state) => {
      return {
        patient_data: state.patient_data,
        social_and_psychosocial: state.social_and_psychosocial,
        physical_examination: state.physical_examination,
        labor_records: state.labor_records,
        files: state.files,
      };
    },
  },
  mutations: {
    saveAssesment(state, payload) {
      state[payload.key] = payload.data;
    },
    removeAssessment(state) {
      state.patient_data = undefined;
      state.social_and_psychosocial = undefined;
      state.physical_examination = undefined;
      state.labor_records = undefined;
      state.files = undefined;
    },
  },
};
