export default {
  namespaced: true,
  state: {
    social_menstruation: undefined,
    pregnancy_info: undefined,
    control_diseases: undefined,
    condition: undefined,
    physical: undefined,
    obstetrics: undefined,
  },
  getters: {
    assessmentData: (state) => {
      return {
        social_menstruation: state.social_menstruation,
        pregnancy_info: state.pregnancy_info,
        control_diseases: state.control_diseases,
        condition: state.condition,
        physical: state.physical,
        obstetrics: state.obstetrics,
      };
    },
  },
  mutations: {
    saveAssesment(state, payload) {
      state[payload.key] = payload.data;
    },
    removeAssessment(state) {
      state.social_menstruation = undefined;
      state.pregnancy_info = undefined;
      state.control_diseases = undefined;
      state.condition = undefined;
      state.physical = undefined;
      state.obstetrics = undefined;
    },
  },
};
