/* eslint-disable */

// sebelum reload jika ada service worker yg waiting kirim message SKIP WAITING
window.addEventListener('beforeunload', async () => {
  console.log('registration unload', registration);
  const registration = await navigator.serviceWorker.getRegistration(
    window.location.origin + '/service-worker.js',
  );
  // const registration = await navigator.serviceWorker.getRegistration(sourceSW);
  if (registration && registration.waiting) {
    // sebelum refresh ganti state ke false
    console.log('invoke update before load');
    localStorage.setItem('updateAvailable', false);
    registration.waiting.postMessage('SKIP_WAITING');
  }
});

// alert update
function invokeUpdate() {
  // saat ada update tersedia set ke true
  console.log('invoke update');
  localStorage.setItem('updateAvailable', true);
}

// service Worker
async function checkUpdate() {
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.register(
      window.location.origin + '/service-worker.js',
    );
    if (registration) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          if (cacheName.startsWith('chunk-')) {
            caches.delete(cacheName);
          }
        });
      });
    }
    if (registration.waiting) {
      console.log('registration waiting');
      invokeUpdate(registration);
    }
    registration.addEventListener('updatefound', (update) => {
      console.log('new update founds listener', update);
      if (registration.installing) {
        registration.installing.addEventListener('statechange', (state) => {
          console.log('state change', state);
          if (registration.waiting) {
            if (navigator.serviceWorker.controller) {
              invokeUpdate(registration);
            } else {
              console.log('service worker initialize');
            }
          }
        });
      }
    });
    let refreshing = false;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (!refreshing) {
        // sebelum refresh ganti state ke false
        localStorage.setItem('updateAvailable', false);
        document.location.reload(true);
        refreshing = true;
      }
    });
  }
}

function clickUpdateInDevTools() {
  // cek jika ada service worker
  if (
    navigator &&
    navigator.serviceWorker &&
    navigator.serviceWorker.getRegistration
  ) {
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration && registration.update) {
        console.log('update found with devtools', registration);
        // Panggil function update di service worker dev tool
        registration.update();
        checkUpdate();
      }
    });
  }
}

// berkala panggil function update di service worker dev tool
const updateInterval = 1 * 60 * 500;
setInterval(clickUpdateInDevTools, updateInterval);
