export default {
  namespaced: true,
  state: {
    autofillDiagnose: null,
    workingInfo: null,
    selectedDetail: null,
  },
  getters: {
    autofillDiagnose: (state) => state.autofillDiagnose,
    workingInfo: (state) => state.workingInfo,
    selectedDetail: (state) => state.selectedDetail,
  },
  mutations: {
    saveDiagnose(state, payload) {
      state.autofillDiagnose = payload.data;
      // payload.data.forEach(arr => {
      //     state.autofillDiagnose.push(arr)
      // });
      // console.log(state, payload)
    },
    saveWorking(state, payload) {
      state.workingInfo = payload.data;
      // console.log(state, payload)
    },
    getSelectedData(state, payload) {
      console.log(payload);
      state.selectedDetail = { ...payload.data };
      console.log(state.selectedDetail);
    },
    removeSaveAll(state) {
      state.autofillDiagnose = null;
      state.workingInfo = null;
      state.selectedDetail = null;
    },
    // saveAssessment(state, payload) {
    //     state[payload.key] = payload.data
    // },
    // removeAssessment(state) {
    //     state.incomingPatientData = null
    //     state.historyAndPainAssessment = null
    //     state.ampleAndPrehospitalHistory = null
    //     state.primarySurvey = null
    //     state.traumaScore = null
    //     state.secondarySurvey = null
    //     state.supportingAssessment = null
    //     state.diagnosisAndTherapy = null
    // }
  },
};
