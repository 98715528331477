import Constant from '@/const';

const axios = require('axios');

function checkStringBlank(myString) {
  return /^\s*$/.test(myString);
}

export default {
  namespaced: true,
  state: {
    firstAssesment: {
      patientData: null,
      diseaseHistory: null,
      psychologyAssessment: null,
    },
    advancedAssesment: null,
  },
  getters: {
    firstAssesment: (state) => state.firstAssesment,
    advancedAssesment: (state) => state.advancedAssesment,
  },
  mutations: {
    saveAssesment(state, payload) {
      if (payload.section === '') {
        state[payload.key] = payload.data;
      } else {
        state[payload.key][payload.section] = payload.data;
      }
    },
    removeAssessment(state) {
      state.firstAssesment = {
        patientData: null,
        diseaseHistory: null,
        psychologyAssessment: null,
      };
      state.advancedAssesment = null;
    },
    removeFirstAssesment(state) {
      state.firstAssesment = {
        patientData: null,
        diseaseHistory: null,
        psychologyAssessment: null,
      };
    },
  },
  actions: {
    postFirstAssesment({ getters, rootGetters }) {
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/6/1/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/3/'),
        {
          detail_poly: [
            {
              // TODO: Fix birth order null
              queue_data: getters.firstAssesment.patientData
                ? [
                    getters.firstAssesment.patientData.birthOrder[0],
                    getters.firstAssesment.patientData.birthOrder[1],
                  ]
                : ['', ''],
              detail: [
                {
                  awal: {
                    doctor_diagnosis:
                      getters.firstAssesment.diseaseHistory.doctorDiagnose,
                    physical_complaint:
                      getters.firstAssesment.diseaseHistory.physicalComplaint,
                    psychology_complaint:
                      getters.firstAssesment.diseaseHistory.psychologyComplaint,
                    assesment: {
                      general_description: {
                        general_appearance:
                          getters.firstAssesment.psychologyAssessment
                            .generalLook,
                        attitude:
                          getters.firstAssesment.psychologyAssessment
                            .behaviourCheck,
                        affect:
                          getters.firstAssesment.psychologyAssessment.afek,
                        romance:
                          getters.firstAssesment.psychologyAssessment.romanFace,
                        thought_process:
                          getters.firstAssesment.psychologyAssessment
                            .thinkingProcess,
                        impaired_perception:
                          getters.firstAssesment.psychologyAssessment
                            .perceptionTrouble,
                      },
                    },
                    functional: {
                      memory:
                        getters.firstAssesment.psychologyAssessment.memory,
                      concentration:
                        getters.firstAssesment.psychologyAssessment
                          .concentration,
                      orientation:
                        getters.firstAssesment.psychologyAssessment.orientation,
                      verbal_ability:
                        getters.firstAssesment.psychologyAssessment.verbalSkill,
                      emotion:
                        getters.firstAssesment.psychologyAssessment.emotion,
                      behaviour:
                        getters.firstAssesment.psychologyAssessment.behaviour,
                    },
                    psychology_test: {
                      result: checkStringBlank(
                        getters.firstAssesment.psychologyAssessment
                          .psychoResults[
                          getters.firstAssesment.psychologyAssessment
                            .psychoResults.length - 1
                        ],
                      )
                        ? getters.firstAssesment.psychologyAssessment.psychoResults.slice(
                            0,
                            getters.firstAssesment.psychologyAssessment
                              .psychoResults.length - 1,
                          )
                        : getters.firstAssesment.psychologyAssessment
                            .psychoResults,
                      tool: checkStringBlank(
                        getters.firstAssesment.psychologyAssessment.psychoTools[
                          getters.firstAssesment.psychologyAssessment
                            .psychoTools.length - 1
                        ],
                      )
                        ? getters.firstAssesment.psychologyAssessment.psychoTools.slice(
                            0,
                            getters.firstAssesment.psychologyAssessment
                              .psychoTools.length - 1,
                          )
                        : getters.firstAssesment.psychologyAssessment
                            .psychoTools,
                    },
                    simtom: getters.firstAssesment.psychologyAssessment.simptom,
                    dinamic_psyhology:
                      getters.firstAssesment.psychologyAssessment
                        .dinamicPsychology,
                    psychology_diagnosis:
                      getters.firstAssesment.psychologyAssessment
                        .psychologyDiagnose,
                    plan_intervensi: checkStringBlank(
                      getters.firstAssesment.psychologyAssessment
                        .interventionPlan[
                        getters.firstAssesment.psychologyAssessment
                          .interventionPlan.length - 1
                      ],
                    )
                      ? getters.firstAssesment.psychologyAssessment.interventionPlan.slice(
                          0,
                          getters.firstAssesment.psychologyAssessment
                            .interventionPlan.length - 1,
                        )
                      : getters.firstAssesment.psychologyAssessment
                          .interventionPlan,
                    intervensi_psychology:
                      getters.firstAssesment.psychologyAssessment
                        .interventionAction,
                    timestamps: {
                      created_by: rootGetters.userLoggedIn.id,
                    },
                  },
                  type_assesment: true,
                },
              ],
            },
          ],
        },
      );
    },
    postAdvancedAssesment({ getters, rootGetters }) {
      const payload = {
        detail_poly: [
          {
            detail: [
              {
                awal: {
                  doctor_diagnosis:
                    getters.firstAssesment.input.diseaseHistory.doctorDiagnose,
                  physical_complaint:
                    getters.firstAssesment.input.diseaseHistory
                      .physicalComplaint,
                  psychology_complaint:
                    getters.firstAssesment.input.diseaseHistory
                      .psychologyComplaint,
                  assesment: {
                    general_description: {
                      general_appearance:
                        getters.firstAssesment.input.psychologyAssessment
                          .generalLook,
                      attitude:
                        getters.firstAssesment.input.psychologyAssessment
                          .behaviourCheck,
                      affect:
                        getters.firstAssesment.input.psychologyAssessment.afek,
                      romance:
                        getters.firstAssesment.input.psychologyAssessment
                          .romanFace,
                      thought_process:
                        getters.firstAssesment.input.psychologyAssessment
                          .thinkingProcess,
                      impaired_perception:
                        getters.firstAssesment.input.psychologyAssessment
                          .perceptionTrouble,
                    },
                  },
                  functional: {
                    memory:
                      getters.firstAssesment.input.psychologyAssessment.memory,
                    concentration:
                      getters.firstAssesment.input.psychologyAssessment
                        .concentration,
                    orientation:
                      getters.firstAssesment.input.psychologyAssessment
                        .orientation,
                    verbal_ability:
                      getters.firstAssesment.input.psychologyAssessment
                        .verbalSkill,
                    emotion:
                      getters.firstAssesment.input.psychologyAssessment.emotion,
                    behaviour:
                      getters.firstAssesment.input.psychologyAssessment
                        .behaviour,
                  },
                  psychology_test: {
                    result: checkStringBlank(
                      getters.firstAssesment.input.psychologyAssessment
                        .psychoResults[
                        getters.firstAssesment.input.psychologyAssessment
                          .psychoResults.length - 1
                      ],
                    )
                      ? getters.firstAssesment.input.psychologyAssessment.psychoResults.slice(
                          0,
                          getters.firstAssesment.input.psychologyAssessment
                            .psychoResults.length - 1,
                        )
                      : getters.firstAssesment.input.psychologyAssessment
                          .psychoResults,
                    tool: checkStringBlank(
                      getters.firstAssesment.input.psychologyAssessment
                        .psychoTools[
                        getters.firstAssesment.input.psychologyAssessment
                          .psychoTools.length - 1
                      ],
                    )
                      ? getters.firstAssesment.input.psychologyAssessment.psychoTools.slice(
                          0,
                          getters.firstAssesment.input.psychologyAssessment
                            .psychoTools.length - 1,
                        )
                      : getters.firstAssesment.input.psychologyAssessment
                          .psychoTools,
                  },
                  simtom:
                    getters.firstAssesment.input.psychologyAssessment.simptom,
                  dinamic_psyhology:
                    getters.firstAssesment.input.psychologyAssessment
                      .dinamicPsychology,
                  psychology_diagnosis:
                    getters.firstAssesment.input.psychologyAssessment
                      .psychologyDiagnose,
                  plan_intervensi: checkStringBlank(
                    getters.firstAssesment.input.psychologyAssessment
                      .interventionPlan[
                      getters.firstAssesment.input.psychologyAssessment
                        .interventionPlan.length - 1
                    ],
                  )
                    ? getters.firstAssesment.input.psychologyAssessment.interventionPlan.slice(
                        0,
                        getters.firstAssesment.input.psychologyAssessment
                          .interventionPlan.length - 1,
                      )
                    : getters.firstAssesment.input.psychologyAssessment
                        .interventionPlan,
                  intervensi_psychology:
                    getters.firstAssesment.input.psychologyAssessment
                      .interventionAction,
                  timestamps: {
                    created_by: rootGetters.userLoggedIn.id,
                  },
                },
                lanjutan: {
                  anamnesis: getters.advancedAssesment.input.anamnesis,
                  checkup: getters.advancedAssesment.input.checkup,
                  diagnosis_tw: getters.advancedAssesment.input.twDiagnosis,
                  evaluation: getters.advancedAssesment.input.evaluation,
                  action: checkStringBlank(
                    getters.advancedAssesment.input.action[
                      getters.advancedAssesment.input.action.length - 1
                    ],
                  )
                    ? getters.advancedAssesment.input.action.slice(
                        0,
                        getters.advancedAssesment.input.action.length - 1,
                      )
                    : getters.advancedAssesment.input.action,
                  plan: checkStringBlank(
                    getters.advancedAssesment.input.plans[
                      getters.advancedAssesment.input.plans.length - 1
                    ],
                  )
                    ? getters.advancedAssesment.input.plans.slice(
                        0,
                        getters.advancedAssesment.input.plans.length - 1,
                      )
                    : getters.advancedAssesment.input.plans,
                  timestamps: {
                    created_by: rootGetters.userLoggedIn.id,
                  },
                },
                type_assesment: false,
              },
            ],
          },
        ],
      };
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/6/1/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/3/'),
        payload,
      );
    },
  },
};
