const state = {
  isBinRohFormChanged: false,
};

const getters = {
  getIsBinRohFormChanged: (state) => state.isBinRohFormChanged,
};

const mutations = {
  setIsBinRohFormChanged(state, payload) {
    state.isBinRohFormChanged = payload;
  },
  resetState(state) {
    state.isBinRohFormChanged = false;
  },
};

export default {
  state,
  getters,
  mutations,
};
