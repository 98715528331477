import {
  getMaxDepth,
  getBackdateDay,
  getBenevolentFundsUsedTypes,
  getBenevolentFundsReceivedTypes,
} from '../../../fetchAPI/finance';

// { , getCoa }
const state = {
  dashboard: {
    maxDepth: 0,
  },
  items: {
    coa: {
      isLoading: false,
      data: [],
    },
  },
  backdateDay: 0,
  benevolentFundsReceivedTypes: [],
  benevolentFundsUsedTypes: [],
};

const getters = {
  getBenevolentFundsReceivedTypes: (state) =>
    state.benevolentFundsReceivedTypes,
  getBenevolentFundsUsedTypes: (state) => state.benevolentFundsUsedTypes,
  getMaxDepth: (state) => state.dashboard.maxDepth,
  getItems: (state) => state.items,
  getBackdateDay: (state) => state.backdateDay,
};

const mutations = {
  setBenevolentFundsReceivedTypes(state, payload) {
    state.benevolentFundsReceivedTypes = payload;
  },

  setBenevolentFundsUsedTypes(state, payload) {
    state.benevolentFundsUsedTypes = payload;
  },

  setMaxDepth(state, payload) {
    state.dashboard.maxDepth = payload;
  },

  setItems(state, payload) {
    const { label, value, isLoading } = payload;
    state.items[label] = {
      ...state[label],
      data: value,
      isLoading,
    };
  },

  setBackdateDay(state, payload) {
    state.backdateDay = payload;
  },
};

const actions = {
  async resolveGetBenevolentFundsReceivedTypes({ commit }) {
    try {
      const responseBenevolentFundsReceivedTypes =
        await getBenevolentFundsReceivedTypes();
      const mapGetBenevolentFundsReceivedTypes =
        responseBenevolentFundsReceivedTypes.value
          .replace(/[^a-zA-Z, -]/g, '')
          .split(',');
      commit(
        'setBenevolentFundsReceivedTypes',
        mapGetBenevolentFundsReceivedTypes,
      );
      return responseBenevolentFundsReceivedTypes;
    } catch (error) {
      return error;
    }
  },
  async resolveGetBenevolentFundsUsedTypes({ commit }) {
    try {
      const responseBenevolentFundsUsedTypes =
        await getBenevolentFundsUsedTypes();
      const mapGetBenevolentFundsUsedTypes =
        responseBenevolentFundsUsedTypes.value
          .replace(/[^a-zA-Z, ]/g, '')
          .split(',');
      commit('setBenevolentFundsUsedTypes', mapGetBenevolentFundsUsedTypes);
      return responseBenevolentFundsUsedTypes;
    } catch (error) {
      return error;
    }
  },
  async resolveGetMaxDepth({ commit }) {
    try {
      const responseGetMaxDepth = await getMaxDepth();
      const mapGetMaxDepth = parseInt(
        JSON.parse(responseGetMaxDepth.value),
        10,
      );
      commit('setMaxDepth', mapGetMaxDepth);
      return responseGetMaxDepth;
    } catch (error) {
      return error;
    }
  },

  //   async resolveGetCoa(_, maxDepth) {
  //     try {
  //       const responseGetCoa = await getCoa(maxDepth);
  //     } catch (error) {
  //       return error;
  //     }
  //   },

  async resolveGetBackdateDay({ commit }) {
    try {
      const responseBackdateDay = await getBackdateDay();
      const mapGetBackdateDay = parseInt(
        JSON.parse(responseBackdateDay.value),
        10,
      );
      commit('setBackdateDay', mapGetBackdateDay);
      return responseBackdateDay;
    } catch (error) {
      return error;
    }
  },
};

export default { state, getters, mutations, actions };
