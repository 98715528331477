import Constant from '@/const';

const axios = require('axios');
const moment = require('moment');

function checkStringBlank(myString) {
  return /^\s*$/.test(myString);
}

export default {
  namespaced: true,
  state: {
    isFirstTime: false,
    fisioAssessment: {
      anamnesisFill: null,
      supportData: null,
      physicalCheckup: null,
      generalCheck: null,
      basicMeasuring: null,
      medicalIntervention: null,
    },
  },
  getters: {
    fisioAssessment: state => state.fisioAssessment,
  },
  mutations: {
    changeFirstTime(state, payload) {
      state.isFirstTime = payload;
    },
    saveAssessment(state, payload) {
      if (payload.section === '') {
        state[payload.key] = payload.data;
      } else {
        state[payload.key][payload.section] = payload.data;
      }
    },
    removeAssessment(state) {
      state.fisioAssessment = {
        anamnesisFill: null,
        supportData: null,
        physicalCheckup: null,
        generalCheck: null,
        basicMeasuring: null,
        medicalIntervention: null,
      };
    },
  },
  actions: {
    postIntervention({ getters, rootGetters }) {
      const postPayload = {
        detail_poly: [
          {
            intervensi_detail: [
              {
                intervensi: getters.fisioAssessment.medicalIntervention.todayIntervention.join(
                  ',',
                ),
                follow_up: getters.fisioAssessment.medicalIntervention.follow_up.filter(
                  arr => arr && arr.id,
                ),
                area: getters.fisioAssessment.medicalIntervention.todayPlace,
                evaluation:
                  getters.fisioAssessment.medicalIntervention.finalEvaluation,
                timestamps: {
                  created_by: rootGetters.userLoggedIn.id,
                },
              },
            ],
          },
        ],
      };
      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.detail_poly[0].intervensi_detail[0] = {
          ...postPayload.detail_poly[0].intervensi_detail[0],
          timestamps: {
            created_by: getters.fisioAssessment.medicalIntervention.created_by,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/7/2/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/4'),
        postPayload,
      );
    },
    postFisioAssessment({ getters, rootGetters }) {
      let visitorFill;
      let generalCheckFill;
      if (getters.fisioAssessment.supportData !== null) {
        visitorFill = {
          radiologi: getters.fisioAssessment.supportData.radiology,
          labotary: getters.fisioAssessment.supportData.labotary,
          emg: getters.fisioAssessment.supportData.emg,
          other: getters.fisioAssessment.supportData.other,
        };
      } else {
        visitorFill = {
          radiologi: '',
          labotary: '',
          emg: '',
          other: '',
        };
      }
      if (getters.fisioAssessment.generalCheck !== null) {
        generalCheckFill = {
          static: {
            oedem: {
              type: getters.fisioAssessment.generalCheck.statis.oedam.type,
              detail: getters.fisioAssessment.generalCheck.statis.oedam.detail,
            },
            keformitas: {
              type: getters.fisioAssessment.generalCheck.statis.keformitas.type,
              detail:
                getters.fisioAssessment.generalCheck.statis.keformitas.detail,
            },
            deformitas: {
              type: getters.fisioAssessment.generalCheck.statis.deformitas.type,
              detail:
                getters.fisioAssessment.generalCheck.statis.deformitas.detail,
            },
            atropi: {
              type: getters.fisioAssessment.generalCheck.statis.atropi.type,
              detail: getters.fisioAssessment.generalCheck.statis.atropi.detail,
            },
            kontraktor: {
              type: getters.fisioAssessment.generalCheck.statis.kontraktor.type,
              detail:
                getters.fisioAssessment.generalCheck.statis.kontraktor.detail,
            },
          },
          dynamic: {
            walk_pattern: {
              type:
                getters.fisioAssessment.generalCheck.dinamis.walkingPattern
                  .type,
              detail:
                getters.fisioAssessment.generalCheck.dinamis.walkingPattern
                  .detail,
            },
            posture: {
              type:
                getters.fisioAssessment.generalCheck.dinamis.bodyBehavior.type,
              detail:
                getters.fisioAssessment.generalCheck.dinamis.bodyBehavior
                  .detail,
            },
            other_activities: {
              type:
                getters.fisioAssessment.generalCheck.dinamis.otherActivity.type,
              detail:
                getters.fisioAssessment.generalCheck.dinamis.otherActivity
                  .detail,
            },
          },
          palpation: {
            local_temperature: {
              type: getters.fisioAssessment.generalCheck.palpasi.localTemp.type,
              detail:
                getters.fisioAssessment.generalCheck.palpasi.localTemp.detail,
            },
            spasme: {
              type: getters.fisioAssessment.generalCheck.palpasi.spasme.type,
              detail:
                getters.fisioAssessment.generalCheck.palpasi.spasme.detail,
            },
            pitting_oedem: {
              type:
                getters.fisioAssessment.generalCheck.palpasi.pittingOedem.type,
              detail:
                getters.fisioAssessment.generalCheck.palpasi.pittingOedem
                  .detail,
            },
          },
        };
      } else {
        generalCheckFill = {
          static: {
            oedem: {
              type: false,
              detail: '',
            },
            keformitas: {
              type: false,
              detail: '',
            },
            deformitas: {
              type: false,
              detail: '',
            },
            atropi: {
              type: false,
              detail: '',
            },
            kontraktor: {
              type: false,
              detail: '',
            },
          },
          dynamic: {
            walk_pattern: {
              type: false,
              detail: '',
            },
            posture: {
              type: false,
              detail: '',
            },
            other_activities: {
              type: false,
              detail: '',
            },
          },
          palpation: {
            local_temperature: {
              type: false,
              detail: '',
            },
            spasme: {
              type: false,
              detail: '',
            },
            pitting_oedem: {
              type: false,
              detail: '',
            },
          },
        };
      }
      const postPayload = {
        isRefference: rootGetters.selectedPatient.isMedicalSupport || false,
        detail_poly: [
          {
            detail_nursery: [
              {
                anamnesis_type: getters.fisioAssessment.anamnesisFill.anamnesis,
                complaint:
                  getters.fisioAssessment.anamnesisFill.primaryComplaint,
                history_of_disease_now: checkStringBlank(
                  getters.fisioAssessment.anamnesisFill.currentSickness[
                    getters.fisioAssessment.anamnesisFill.currentSickness
                      .length - 1
                  ],
                )
                  ? getters.fisioAssessment.anamnesisFill.currentSickness.slice(
                      0,
                      getters.fisioAssessment.anamnesisFill.currentSickness
                        .length - 1,
                    )
                  : getters.fisioAssessment.anamnesisFill.currentSickness,
                history_of_disease_last: checkStringBlank(
                  getters.fisioAssessment.anamnesisFill.historySickness[
                    getters.fisioAssessment.anamnesisFill.historySickness
                      .length - 1
                  ],
                )
                  ? getters.fisioAssessment.anamnesisFill.historySickness.slice(
                      0,
                      getters.fisioAssessment.anamnesisFill.historySickness
                        .length - 1,
                    )
                  : getters.fisioAssessment.anamnesisFill.historySickness,
                visitor: visitorFill,
                physical_checkup: {
                  td: [
                    getters.fisioAssessment.physicalCheckup.td[0],
                    getters.fisioAssessment.physicalCheckup.td[1],
                  ],
                  hr: getters.fisioAssessment.physicalCheckup.hr,
                  temp: getters.fisioAssessment.physicalCheckup.temperature,
                  rr: getters.fisioAssessment.physicalCheckup.rr,
                  height: getters.fisioAssessment.physicalCheckup.height,
                  weight: getters.fisioAssessment.physicalCheckup.weight,
                  pain_score:
                    getters.fisioAssessment.physicalCheckup.pain === ''
                      ? 0
                      : getters.fisioAssessment.physicalCheckup.pain,
                  deep_pain:
                    getters.fisioAssessment.physicalCheckup.innerPain === ''
                      ? 0
                      : getters.fisioAssessment.physicalCheckup.innerPain,
                  pressure_pain:
                    getters.fisioAssessment.physicalCheckup.pressurePain === ''
                      ? 0
                      : getters.fisioAssessment.physicalCheckup.pressurePain,
                  motion_pain:
                    getters.fisioAssessment.physicalCheckup.movingPain === ''
                      ? 0
                      : getters.fisioAssessment.physicalCheckup.movingPain,
                },
                functional_ability:
                  getters.fisioAssessment.physicalCheckup.functionalAbility,
                basic_inspection: generalCheckFill,
                basic_measurement: {
                  lgs: getters.fisioAssessment.basicMeasuring.lgs,
                  mmt: getters.fisioAssessment.basicMeasuring.mmt,
                  antropometri:
                    getters.fisioAssessment.basicMeasuring.antropometri,
                  refleks: getters.fisioAssessment.basicMeasuring.reflex,
                  cardipulmunal:
                    getters.fisioAssessment.basicMeasuring.candipulmunal,
                  special_inspection:
                    getters.fisioAssessment.basicMeasuring.spesificInspect,
                  fisioterapi_diagnosis: getters.fisioAssessment.basicMeasuring.fisioDiagnoses.map(
                    arr =>
                      arr.icd && arr.icd.id
                        ? {
                            id: arr.icd.id,
                            text: arr.icd.text,
                            value: arr.icd.value,
                            detail: arr.detail,
                          }
                        : {
                            detail: arr.detail,
                          },
                  ),
                  fisioterapi_plan:
                    getters.fisioAssessment.basicMeasuring.fisioProgramPlan,
                },
                timestamps: {
                  created_by: rootGetters.userLoggedIn.id,
                },
              },
            ],
          },
        ],
      };

      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.detail_poly[0].detail_nursery[0] = {
          ...postPayload.detail_poly[0].detail_nursery[0],
          timestamps: {
            created_by:
              getters.fisioAssessment.basicMeasuring.timestamps.created_by,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/7/1/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/4'),
        postPayload,
      );
    },
  },
};
