const _ = require('lodash');

export default {
  namespaced: true,
  state: {
      supplierIngredients:null,
      ingridientsBySupplier: null,
      saveInStore: false,
      indexOtherBox: null,
      afterPostData: null
  },
  getters: {
      tableIngredients: state => state.supplierIngredients,
      bySupplier: state => state.ingridientsBySupplier,
      afterPostData: state => state.afterPostData
  },
  mutations: {
      saveIndexOther(state,payload){
        state.indexOtherBox = payload
      },
      savePostData(state,payload){
        state.afterPostData = payload
      },
      processingIngredients(state,payload){
        state.supplierIngredients = payload
        const tempIngredient = []
        let supplierUsedList = []
        const bySupplier = []
        payload.forEach(element => {
          // save temporary
          element.ingredients.forEach(arr=>{
            tempIngredient.push({...arr})
          })
         
          // get supplier used
          element.ingredients.forEach(arr=>{
            supplierUsedList.push(arr.supplier)
          })
        });
        // eliminate if had many same supplier
        supplierUsedList = _.uniqBy(supplierUsedList, 'name').filter(fltr=>fltr)
        // merge all ingridient with the same supplier  
        supplierUsedList.forEach(arr=>{
          const payloadData = {
            supplierName: arr.name,
            id_supplier: arr.id,
            totalPrice: 0,
            ingredients: tempIngredient
            .filter(fltr=>fltr.detail && fltr.supplier && (fltr.supplier.name === arr.name))
            .map(ar=>{
              return{
                name:ar.name,
                quantity:ar.quantity,
                package:ar.package,
                price:ar.detail.hna,
                detail:{...ar.detail}
              }
            })
          }
          bySupplier.push(payloadData)
        })
        state.ingridientsBySupplier = bySupplier
      },
      saveToStore(state,payload){
        state.saveInStore=payload
      },
      clearTableIngredients(state){
        state.supplierIngredients= null
      },
      clearNutritionOrder(state){
        console.log('triggered')
        state.ingridientsBySupplier= null
        state.saveInStore= false
        state.afterPostData= null
      },
  },
}
