export default {
  namespaced: true,
  state: {
    progressLog: {
      isOpen: false,
      isPost: false,
      type: '',
      isLocalSave: false,
    },
  },
  getters: {
    progressLogOpen: (state) => state.progressLog.isOpen,
    progressLogType: (state) => state.progressLog.type,
    postProgressLog: (state) => state.progressLog.isPost,
    statusLocalSave: (state) => state.progressLog.isLocalSave,
  },
  mutations: {
    changeStatusLocalSave(state, payload) {
      state.progressLog.isLocalSave = payload;
    },
    saveStatus(state, payload) {
      console.log('trigger', state, payload);
      state.progressLog.isOpen = payload;
    },
    saveType(state, payload) {
      state.progressLog.type = payload;
    },
    postData(state, payload) {
      console.log('trigger', state, payload);
      state.progressLog.isPost = payload;
    },
    resetAllStatus(state) {
      state.progressLog = {
        ...state.progressLog,
        isOpen: false,
        isPost: false,
      };
    },
  },
};
