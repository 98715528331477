export default {
    namespaced: true,
    state: {
        lastTabUpdate : false,
        incomingPatientData: null,
        historyAndPainAssessment: null,
        ampleAndPrehospitalHistory: null,
        primarySurvey: null,
        traumaScore: null,
        secondarySurvey: null,
        supportingAssessment: null,
        diagnosisAndTherapy: null,
        listRefference : null,
    },
    getters: {
        statusLastTab: (state) => 
            state.lastTabUpdate
        ,
        incomingPatientData: state => state.incomingPatientData,
        historyAndPainAssessment: state => state.historyAndPainAssessment,
        ampleAndPrehospitalHistory: state => state.ampleAndPrehospitalHistory,
        primarySurvey: state => state.primarySurvey,
        traumaScore: state => state.traumaScore,
        secondarySurvey: state => state.secondarySurvey,
        supportingAssessment: state => state.supportingAssessment,
        diagnosisAndTherapy: state => state.diagnosisAndTherapy,
        getListRefference: state => state.listRefference
    },
    mutations: {
        setLastTab(state,payload){
            console.log('set status')
            state.lastTabUpdate = payload
        },
        saveAssessment(state, payload) {
            state[payload.key] = payload.data
        },
        removeAssessment(state) {
            state.incomingPatientData = null
            state.historyAndPainAssessment = null
            state.ampleAndPrehospitalHistory = null
            state.primarySurvey = null
            state.traumaScore = null
            state.secondarySurvey = null
            state.supportingAssessment = null
            state.diagnosisAndTherapy = null
        },
        gettingListRefference(state, payload){
            if(payload.status === 'add'){
                state.listRefference = payload.data
            } else {
                state.listRefference = null
            }
        }
    },
}
