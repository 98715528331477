export default {
  namespaced: true,
  state: {
    lastTabUpdate: false,
    onUpdate: false,
    preliminaryAssessment: null,
    history: null,
    generalConditionAndPhysical: null,
    painAssessment: null,
    socialAndPsychoAssessment: null,
    dischargePlanning: null,
    decubitusAndNutrition: null,
    communicationAndSpiritual: null,
    patientManagement: null,
  },
  getters: {
    statusLastTab: (state) => state.lastTabUpdate,
    onUpdate: (state) => state.onUpdate,
    assessmentData: (state) => {
      return {
        preliminaryAssessment: state.preliminaryAssessment,
        history: state.history,
        generalConditionAndPhysical: state.generalConditionAndPhysical,
        painAssessment: state.painAssessment,
        socialAndPsychoAssessment: state.socialAndPsychoAssessment,
        dischargePlanning: state.dischargePlanning,
        decubitusAndNutrition: state.decubitusAndNutrition,
        communicationAndSpiritual: state.communicationAndSpiritual,
        patientManagement: state.patientManagement,
      };
    },
  },
  mutations: {
    changeOnUpdate(state, payload) {
      state.onUpdate = payload;
    },
    setLastTab(state, payload) {
      console.log('set status');
      state.lastTabUpdate = payload;
    },
    saveAssessment(state, payload) {
      state[payload.key] = payload.data;
    },
    removeAssessment(state) {
      state.preliminaryAssessment = null;
      state.history = null;
      state.generalConditionAndPhysical = null;
      state.painAssessment = null;
      state.socialAndPsychoAssessment = null;
      state.dischargePlanning = null;
      state.decubitusAndNutrition = null;
      state.communicationAndSpiritual = null;
      state.patientManagement = null;
    },
  },
  removeAssessment(state) {
    state.preliminaryAssessment = null;
    state.history = null;
    state.generalConditionAndPhysical = null;
    state.painAssessment = null;
    state.socialAndPsychoAssessment = null;
    state.dischargePlanning = null;
    state.decubitusAndNutrition = null;
    state.communicationAndSpiritual = null;
    state.patientManagement = null;
  },
};
