<template>
  <div id="app" class="main-display">
    <v-app>
      <router-view />
    </v-app>
  </div>
</template>

<script>
import Constant from '@/const';

const axios = require('axios');

export default {
  name: 'App',
  metaInfo: {
    meta: [{ version: Constant.version }],
  },
  mounted() {
    this.$nextTick(() => {
      this.getClientInfo();
    });
  },
  methods: {
    async getClientInfo() {
      let clientStorage = this.$store.getters['configuration/clientData'];
      if (
        clientStorage === null ||
        clientStorage.info === null ||
        clientStorage.info.name === ''
      ) {
        await this.$store.dispatch('configuration/getClient');

        clientStorage = this.$store.getters['configuration/clientData'];
      }
    },
  },
  beforeMount() {
    axios.defaults.headers.common.Authorization = `Bearer ${this.$store.getters.token}`;
  },
};
</script>

<style lang="scss">
@import '~@/assets/scss/variables.scss';

a {
  text-decoration: none;
}

#app {
  font-family: 'Roboto', 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;
  min-height: 100vh !important;
  max-height: 100vh !important;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
// .v-tabs__wrapper {
//   justify-content: center;

//   .v-tabs__container {
//     flex-grow: 0;

//     .v-tabs__div {
//       text-transform: none;

//       .v-tabs__item {
//         &.v-tabs__item--active {
//           color: $primary-color;
//         }
//       }
//     }
//   }
// }
</style>
