import Constant from '@/const';

const _ = require('lodash');
const axios = require('axios');
const moment = require('moment');

function checkStringBlank(myString) {
  return /^\s*$/.test(myString);
}

export default {
  namespaced: true,
  state: {
    isAdvancedAssesment: false,
    addressedSpecialist: null,
    nurseAssesment: {
      subjectiveData: null,
      objectiveData: null,
      painAssesment: null,
      nutritionScreening: null,
      prayingAbility: null,
    },
    doctorAssesment: null,
  },
  getters: {
    isAdvancedAssesment: (state) => state.isAdvancedAssesment,
    addressedSpecialist: (state) => state.addressedSpecialist,
    nurseAssesment: (state) => state.nurseAssesment,
    doctorAssesment: (state) => state.doctorAssesment,
  },
  mutations: {
    changeAssesment(state, payload) {
      state.isAdvancedAssesment = payload;
    },
    saveAssesment(state, payload) {
      if (payload.section === '') {
        state[payload.key] = payload.data;
      } else {
        state[payload.key][payload.section] = payload.data;
      }
    },
    removeAssessment(state) {
      state.addressedSpecialist = null;
      state.nurseAssesment = {
        subjectiveData: null,
        objectiveData: null,
        painAssesment: null,
        nutritionScreening: null,
        prayingAbility: null,
      };
      state.doctorAssesment = null;
    },
  },
  actions: {
    postDiagnose({ getters, rootGetters }) {
      return axios.post(
        Constant.apiUrl
          .concat('/patient/action/diagnosis/2/2/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/1/'),
        {
          detail: [
            {
              diagnosis: checkStringBlank(
                getters.doctorAssesment.diagnoses[
                  getters.doctorAssesment.diagnoses.length - 1
                ],
              )
                ? getters.doctorAssesment.diagnoses.slice(
                    0,
                    getters.doctorAssesment.diagnoses.length - 1,
                  )
                : getters.doctorAssesment.diagnoses,
              timestamps: {
                created_by: rootGetters.userLoggedIn.id,
              },
            },
          ],
        },
      );
    },
    postNurseAssesment({ getters, rootGetters }, payload) {
      const nurseplan = [];
      getters.nurseAssesment.prayingAbility.diagnoses.forEach((diagnose) => {
        diagnose.plans.forEach((plan) => {
          if (plan.id_plan) nurseplan.push(plan.id_plan);
        });
      });
      const postPayload = {
        detail_poly: [
          {
            flag: getters.isAdvancedAssesment,
            detail_nursery: [
              {
                intended_specialization: 1,
                subjective: {
                  primary_complaint:
                    getters.nurseAssesment.subjectiveData.description,
                  tooth_deasease_history:
                    getters.nurseAssesment.subjectiveData.historyOfDentalHealth,
                  habit: getters.nurseAssesment.subjectiveData.habit,
                  psychospiritual_history: {
                    type: getters.nurseAssesment.subjectiveData
                      .psychospiritualHistory.type,
                    detail:
                      getters.nurseAssesment.subjectiveData
                        .psychospiritualHistory.type === 0
                        ? []
                        : getters.nurseAssesment.subjectiveData
                            .psychospiritualHistory.value,
                  },
                  alergic_history: {
                    type:
                      getters.nurseAssesment.subjectiveData.allergyHistory === 0
                        ? 1
                        : 0,
                    detail:
                      getters.nurseAssesment.subjectiveData.allergyHistory === 1
                        ? getters.nurseAssesment.subjectiveData.allergies
                            .filter((arr) => arr)
                            .map((data) => {
                              return {
                                type: data,
                              };
                            })
                        : [],
                  },
                  drug_history: {
                    type: getters.nurseAssesment.subjectiveData.drugs.type,
                    detail: checkStringBlank(
                      getters.nurseAssesment.subjectiveData.drugs.detail[
                        getters.nurseAssesment.subjectiveData.drugs.detail
                          .length - 1
                      ],
                    )
                      ? getters.nurseAssesment.subjectiveData.drugs.detail.slice(
                          0,
                          getters.nurseAssesment.subjectiveData.drugs.detail
                            .length - 1,
                        )
                      : getters.nurseAssesment.subjectiveData.drugs.detail,
                    dosage: checkStringBlank(
                      getters.nurseAssesment.subjectiveData.drugs.dose[
                        getters.nurseAssesment.subjectiveData.drugs.dose
                          .length - 1
                      ],
                    )
                      ? getters.nurseAssesment.subjectiveData.drugs.dose.slice(
                          0,
                          getters.nurseAssesment.subjectiveData.drugs.dose
                            .length - 1,
                        )
                      : getters.nurseAssesment.subjectiveData.drugs.dose,
                    timestamps: checkStringBlank(
                      getters.nurseAssesment.subjectiveData.drugs.lastUsage[
                        getters.nurseAssesment.subjectiveData.drugs.lastUsage
                          .length - 1
                      ],
                    )
                      ? getters.nurseAssesment.subjectiveData.drugs.lastUsage.slice(
                          0,
                          getters.nurseAssesment.subjectiveData.drugs.lastUsage
                            .length - 1,
                        )
                      : getters.nurseAssesment.subjectiveData.drugs.lastUsage,
                  },
                },
                objective: {
                  general_condition:
                    getters.nurseAssesment.objectiveData.generalCondition,
                  ttv: {
                    blood: [
                      getters.nurseAssesment.objectiveData.sistol,
                      getters.nurseAssesment.objectiveData.diastol,
                    ],
                    temperature: getters.nurseAssesment.objectiveData.ttv[1],
                    pulse: getters.nurseAssesment.objectiveData.ttv[2],
                    RR: getters.nurseAssesment.objectiveData.ttv[3],
                    saturation: getters.nurseAssesment.objectiveData.ttv[4],
                    height: getters.nurseAssesment.objectiveData.ttv[5],
                    weight: getters.nurseAssesment.objectiveData.ttv[6],
                  },

                  pain_assessment: {
                    type:
                      _.get(
                        getters,
                        'nurseAssessment.painAssessment.input.pain',
                      ) === 0,
                    detail: {
                      score: _.get(
                        getters,
                        'nurseAssessment.painAssessment.input.intensity.level',
                      ),
                      provocation: _.get(
                        getters,
                        'nurseAssessment.painAssessment.input.provocation.value',
                        [],
                      )
                        .map((data, index) => ({
                          index,
                          value: data,
                        }))
                        .filter((data) => data.value)
                        .map((data) => ({
                          type:
                            data.index === 4
                              ? _.get(
                                  getters,
                                  'nurseAssessment.painAssessment.input.provocation.detail',
                                )
                              : _.get(
                                  getters,
                                  'nurseAssessment.painAssessment.labels.provocation[data.index]',
                                ),
                        })),
                      quality: _.get(
                        getters,
                        'nurseAssessment.painAssessment.input.quality.value',
                        [],
                      )
                        .map((data, index) => ({
                          index,
                          value: data,
                        }))
                        .filter((data) => data.value)
                        .map((data) => ({
                          type:
                            data.index === 8
                              ? _.get(
                                  getters,
                                  'nurseAssessment.painAssessment.input.quality.detail',
                                )
                              : _.get(
                                  getters,
                                  'nurseAssessment.painAssessment.labels.quality[data.index]',
                                ),
                        })),
                      radiation:
                        _.get(
                          getters,
                          'nurseAssessment.painAssessment.input.radiation',
                        ) === 1,
                      severity: _.get(
                        getters,
                        'nurseAssessment.painAssessment.input.severity',
                      ),
                      time: {
                        type:
                          _.get(
                            getters,
                            'nurseAssessment.painAssessment.input.time',
                          ) === 1,
                        duration:
                          _.get(
                            getters,
                            'nurseAssessment.painAssessment.input.timeDuration',
                          ) === 1,
                      },
                    },
                  },
                  nutrition_screening: getters.isAdvancedAssesment
                    ? { no1: 0, no2: 0 }
                    : {
                        no1: _.get(
                          getters,
                          'nurseAssesment.nutritionScreening.input.weightDrop',
                        ),
                        no2: _.get(
                          getters,
                          'nurseAssesment.nutritionScreening.input.foodIntakeDrop',
                        ),
                      },
                  praying_ability: {
                    no1: {
                      type: getters.nurseAssesment.prayingAbility
                        .obligatoryPrayer.type,
                      detail:
                        getters.nurseAssesment.prayingAbility.obligatoryPrayer
                          .type === 2
                          ? getters.nurseAssesment.prayingAbility
                              .obligatoryPrayer.detail
                          : '',
                    },
                    no2: getters.nurseAssesment.prayingAbility.wudhu,
                    no3: getters.nurseAssesment.prayingAbility.sholat,
                  },
                  psychology:
                    getters.nurseAssesment.prayingAbility.psychologist,
                  socio_economic:
                    getters.nurseAssesment.prayingAbility.socialEconomy,
                  // nursery_diagnosis:
                  //   getters.nurseAssesment.prayingAbility.diagnoses.map(
                  //     (item) => {
                  //       let plans = item.plans
                  //         .filter((pl) => pl || pl === 0)
                  //         .map((plan) => (plan.value ? plan.value : plan));
                  //       plans = plans.filter((a, i) => plans.indexOf(a) === i);
                  //       return {
                  //         data: item.detail,
                  //         plan: plans,
                  //         // .slice(0, item.plans.length - 1),
                  //       };
                  //     },
                  //   ),
                  nurseplan,
                  extra_oral_examination: {
                    face:
                      getters.nurseAssesment.prayingAbility.dental.face ||
                      'normal',
                    lymphe:
                      getters.nurseAssesment.prayingAbility.dental.lymph ||
                      'normal',
                  },
                  intra_oral_examination: {
                    lip:
                      getters.nurseAssesment.prayingAbility.dental.lip ||
                      'normal',
                    oral_mucosa:
                      getters.nurseAssesment.prayingAbility.dental.mouth ||
                      'normal',
                    tongue:
                      getters.nurseAssesment.prayingAbility.dental.tongue ||
                      'normal',
                    gingiva:
                      getters.nurseAssesment.prayingAbility.dental.gingiva ||
                      'normal',
                  },
                },
                timestamps: {
                  created_by: rootGetters.userLoggedIn.id,
                },
              },
            ],
          },
        ],
      };
      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.detail_poly[0].detail_nursery[0] = {
          ...postPayload.detail_poly[0].detail_nursery[0],
          timestamps: {
            created_by: getters.nurseAssesment.subjectiveData.created_by,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/')
          .concat(payload.polyId)
          .concat('/1/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/2'),
        postPayload,
      );
    },
    postDoctorAssesment({ getters, rootGetters }, payload) {
      const postPayload = {
        detail_poly: [
          {
            detail_doctor: [
              {
                anamnesis: checkStringBlank(
                  getters.doctorAssesment.input.anamnesis[
                    getters.doctorAssesment.input.anamnesis.length - 1
                  ],
                )
                  ? getters.doctorAssesment.input.anamnesis.slice(
                      0,
                      getters.doctorAssesment.input.anamnesis.length - 1,
                    )
                  : getters.doctorAssesment.input.anamnesis,
                physical_checkup: [
                  {
                    image: getters.doctorAssesment.input.dentalCoordinates.map(
                      (item) => {
                        return {
                          type: item.type,
                          position: item.position,
                        };
                      },
                    ),
                    detail: getters.doctorAssesment.input.dentalDetail.map(
                      (item) => (item === '' ? 'normal' : item),
                    ),
                  },
                ],
                // diagnostic: checkStringBlank(
                //   getters.doctorAssesment.input.diagnoses[
                //     getters.doctorAssesment.input.diagnoses.length - 1
                //   ],
                // )
                //   ? getters.doctorAssesment.input.diagnoses.slice(
                //       0,
                //       getters.doctorAssesment.input.diagnoses.length - 1,
                //     )
                //   : getters.doctorAssesment.input.diagnoses,
                diagnostic: getters.doctorAssesment.input.diagnosis.map(
                  (diagnose) => {
                    return diagnose.icd && diagnose.icd.id
                      ? {
                          _id: diagnose.icd.id,
                          value: diagnose.icd.value,
                          text: diagnose.icd.text,
                          detail: diagnose.detail,
                        }
                      : {
                          detail: diagnose.detail,
                        };
                  },
                ),
                plan: getters.doctorAssesment.input.plan,
                follow_up: getters.doctorAssesment.input.follow_up.map(
                  (arr) => {
                    return arr.icd && arr.icd.id
                      ? {
                          _id: arr.icd.id,
                          value: arr.icd.value,
                          text: arr.icd.text,
                          detail: arr.detail,
                        }
                      : { detail: arr.detail };
                  },
                ),
                return_status: {
                  type: getters.doctorAssesment.input.action,
                  // detail: getters.doctorAssesment.input.referral,
                  detail:
                    getters.doctorAssesment.input.action === 3
                      ? getters.doctorAssesment.input.internalReferral
                      : getters.doctorAssesment.input.referral,
                },
                instruction: getters.doctorAssesment.input.instruction,
                timestamps: {
                  created_by: rootGetters.userLoggedIn.id,
                },
              },
            ],
          },
        ],
      };
      if (rootGetters.userLoggedIn.role.primary.toLowerCase() === 'casemix') {
        postPayload.detail_poly[0].detail_doctor[0] = {
          ...postPayload.detail_poly[0].detail_doctor[0],
          timestamps: {
            created_by: getters.doctorAssesment.input.timestamps.created_by,
            corrected_by: rootGetters.userLoggedIn.id,
            corrected_at: moment().format(),
          },
        };
      }
      return axios.post(
        Constant.apiUrl
          .concat('/patient/poly/')
          .concat(payload.polyId)
          .concat('/1/')
          .concat(rootGetters.selectedPatient.id_queue)
          .concat('/1'),
        postPayload,
      );
    },
  },
};
