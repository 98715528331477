export default {
  namespaced: true,
  state: {
    mother_status: undefined,
    resustation_care: undefined,
    physical_assessment: undefined,
    mother_baby_stamp: undefined,
    pain_assessment: undefined,
    evaluation_discharge_planning: undefined,
    nursing_diagnoses: undefined,
  },
  getters: {
    assessmentData: (state) => {
      return {
        mother_status: state.mother_status,
        resustation_care: state.resustation_care,
        physical_assessment: state.physical_assessment,
        mother_baby_stamp: state.mother_baby_stamp,
        pain_assessment: state.pain_assessment,
        evaluation_discharge_planning: state.evaluation_discharge_planning,
        nursing_diagnoses: state.nursing_diagnoses,
      };
    },
  },
  mutations: {
    saveAssesment(state, payload) {
      state[payload.key] = payload.data;
    },
    removeAssessment(state) {
      state.mother_status = undefined;
      state.resustation_care = undefined;
      state.physical_assessment = undefined;
      state.mother_baby_stamp = undefined;
      state.pain_assessment = undefined;
      state.evaluation_discharge_planning = undefined;
      state.nursing_diagnoses = undefined;
    },
  },
};
