import moment from 'moment';

const _ = require('lodash');

export default {
  namespaced: true,
  state: {
      canPost : false,
      changeTab : 0,
      isPost: false,
      isUpdate:false,
      input: null,
      inputUpdate: null,
      tempInput: {},
      selectedAssesmentChange: false,
      defaultInput: {
        room: '',
        dateIn: null,
        bbDefault: null,
        dietType: '',
        interviewDate: moment().format('YYYY-MM-DD'),
        bbHospital: null,
        dietName: null,
        medicDiagnose: null,
        heightBody: null,
        weightBody: {
          lostWeight: null,
          lostTwoWeek: null,
        },
        foodIntake: {
          intakeTotal: null,
          intakeTime: null,
        },
        anoreksia: {
          status: null,
          freq: null,
          other: null,
        },
        nauseous: {
          status: null,
          freq: null,
          other: null,
        },
        vomit: {
          status: null,
          freq: null,
          other: null,
        },
        diarrhea: {
          status: null,
          freq: null,
          other: null,
        },
        conclusion: null,
        descriptionFunctional: null,
        generalMetabloliscDistraction: {
          status: null,
          category: null,
        },
        physicalExam: {
          massMuscleLoss: null,
          subcutaneousFatLoss: null,
          edemaSelect: null,
          asitesSelect: null,
        },
      },
      totalScore: {
        aScore: 0,
        bScore: 0,
        cScore: 0,
      },
      resource: {
        lostWeight: [
          { text: 'Tidak ada(A)', value: 1, realValue: 'A' },
          { text: 'Ada penurunan BB 5-10% (B)', value: 2, realValue: 'B' },
          { text: 'Tidak tahu (tidak di score)', value: 0, realValue: '-' },
          {
            text: 'Ada perubahan, bertambah atau menurun < 5% (B)',
            value: 3,
            realValue: 'B',
          },
          { text: 'Ada penurunan 10%  (C)', value: 4, realvalue: 'C' },
        ],
        weightTwoWeek: [
          { text: 'Tidak ada(A)', value: 1, realValue: 'A' },
          {
            text: 'Ada kenaikan, tapi BB belum normal (B)',
            value: 2,
            realValue: 'B',
          },
          {
            text: 'Tidak ada, tapi BB di bawah atau di atas normal (B)',
            value: 3,
            realValue: 'B',
          },
          { text: 'BB turun (C)', value: 4, realValue: 'C' },
        ],
        intakeTotalChange: [
          {
            text: 'Asupan cukup & tidak ada perubahan; kalaupun ada hanya sedikit dan atau dalam waktu singkat (A))',
            value: 1,
            realValue: 'A',
          },
          {
            text: 'Asupan rendah tapi ada peningkatan (B)',
            value: 2,
            realValue: 'B',
          },
          {
            text: 'Asupan menurun daripada sebelum sakit tapi ringan (B)',
            value: 3,
            realValue: 'B',
          },
          {
            text: 'Asupan sangat tidak cukup dan menurun tahap berat daripada sebelumnya (C)',
            value: 4,
            realValue: 'C',
          },
        ],
        intakeTimeChange: [
          {
            text: '< 2 minggu, sedikit/tanpa perubahan  (A)',
            value: 1,
            realValue: 'A',
          },
          {
            text: '> 2 minggu, perubahan sedang (B)',
            value: 2,
            realValue: 'B',
          },
          {
            text: 'Tak bisa makan, perubahan drastis (C)',
            value: 3,
            realValue: 'C',
          },
        ],
        conclusion: [
          {
            text: 'Beberapa gejala atau tidak ada gejala, sebentar-sebentar (A)',
            value: 1,
            realValue: 'A',
          },
          {
            text: 'Ada beberapa gejala > 2 minggu (B)',
            value: 2,
            realValue: 'B',
          },
          {
            text: '> 1 atau semua gejala setiap hari/teratur > 2 minggu (C)',
            value: 3,
            realValue: 'C',
          },
        ],
        bodyFunctionState: [
          {
            text: 'Aktivitas normal, tidak ada kelainan, kekuatan/stamina tetap (A)',
            value: 1,
            realValue: 'A',
          },
          {
            text: 'Aktivitas ringan, mengalami hanya sedikit penurunan (tahap ringan) (B)',
            value: 2,
            realValue: 'B',
          },
          {
            text: 'Tanpa aktivitas/di tempat tidur, penurunan kekuatan/stamina (tahap buruk) (C)',
            value: 3,
            realValue: 'C',
          },
        ],
        stressMetabolikCategory: [
          {
            text: 'Rendah',
            text2:
              '(mis: hernia inguinal, infeksi, peny. jantung kongestif) (B)',
            value: 1,
            realValue: 'B',
          },
          {
            text: 'Sedang',
            text2: '(mis: DM, pneumonia) (B)',
            value: 2,
            realValue: 'B',
          },
          {
            text: 'Tinggi ',
            text2:
              '(mis: ulcerativ colitis+diare, kanker, peritonitis berat) (C)',
            value: 3,
            realValue: 'C',
          },
        ],
        gastrointestinalFreq: [
          { text: 'Tidak Pernah', value: 0 },
          { text: 'Tiap Hari', value: 1 },
          { text: '2-3 kali/minggu', value: 2 },
          { text: '1-2 kali/minggu', value: 3 },
        ],
        gastrointestinalOther: [
          { text: '> 2 minggu', value: 1 },
          { text: '< 2 minggu', value: 2 },
        ],
        subcutaneousFatLoss: [
          { text: 'Tidak Ada (A)', value: 0, realValue: 'A' },
          { text: 'Salah satu tempat (B)', value: 1, realValue: 'B' },
          { text: 'Kedua tempat (C)', value: 2, realValue: 'C' },
        ],
        massMuscleLoss: [
          { text: 'Tidak Ada (A)', value: 0, realValue: 'A' },
          { text: 'Beberapa tempat (B)', value: 1, realValue: 'B' },
          { text: 'Kedua tempat (C)', value: 2, realValue: 'C' },
        ],
        edemaSelect: [
          { text: 'Tidak Ada (A)', value: 0, realValue: 'A' },
          { text: 'Sedang/tungkai (B)', value: 1, realValue: 'B' },
          { text: 'Berat (anasarka) (C)', value: 2, realValue: 'C' },
        ],
        asitesSelect: [
          { text: 'Tidak Ada (A)', value: 0, realValue: 'A' },
          { text: 'Sedang (B)', value: 1, realValue: 'B' },
          { text: 'Berat (c)', value: 2, realValue: 'C' },
        ],
      },
  },
  getters: {
      postData: state => state.isPost,
      updateData: state => state.isUpdate,
      inputAssesment : state => state.input,
      updateAssesment : state => state.inputUpdate,
      defaultInput : state => state.tempInput,
      canPostData : state => state.canPost,
      changeTab : state => state.changeTab,
      changeSelected: state => state.selectedAssesmentChange
  },
  mutations: {
      changeAssesment(state){
        state.selectedAssesmentChange = !state.selectedAssesmentChange 
      },
      addTempInput(state){
        state.defaultInput = {
          room: '',
          dateIn: null,
          bbDefault: null,
          dietType: '',
          interviewDate: moment().format('YYYY-MM-DD'),
          bbHospital: null,
          dietName: null,
          medicDiagnose: null,
          heightBody: null,
          weightBody: {
            lostWeight: null,
            lostTwoWeek: null,
          },
          foodIntake: {
            intakeTotal: null,
            intakeTime: null,
          },
          anoreksia: {
            status: null,
            freq: null,
            other: null,
          },
          nauseous: {
            status: null,
            freq: null,
            other: null,
          },
          vomit: {
            status: null,
            freq: null,
            other: null,
          },
          diarrhea: {
            status: null,
            freq: null,
            other: null,
          },
          conclusion: null,
          descriptionFunctional: null,
          generalMetabloliscDistraction: {
            status: null,
            category: null,
          },
          physicalExam: {
            massMuscleLoss: null,
            subcutaneousFatLoss: null,
            edemaSelect: null,
            asitesSelect: null,
          },
        }
        state.tempInput ={...state.defaultInput}
      },
      postData(state){
        state.isPost = true
      },
      afterPost(state){
        state.isPost = false
      },
      updatedInput(state,payload){
        if(payload === 'anyUpdate') {
          state.canPost = true
        } else {
          state.canPost = false
        }
      },
      changeTab(state,payload){
        state.changeTab = payload
      },
      updateTemp(state, payload){
        console.log(payload)
        state.inputUpdate = {}
        state.inputUpdate = {
          id_screening: payload.id_screening ,
          room: payload.room,
          dateIn: payload.arrived_at ? moment(payload.arrived_at).format('YYYY-MM-DD') : null,
          bbDefault: payload.usual_weight,
          dietType: payload.prescription,
          interviewDate: payload.interview_date ? moment(payload.interview_date).format('YYYY-MM-DD') : null ,
          bbHospital: payload.current_weight,
          dietName: {
            name:payload.dietisien_name,
            id:payload.dietisien,
          } ,
          medicDiagnose: payload.diagnose,
          heightBody: payload.height,
          weightBody: {
            lostWeight: _.find(state.resource.lostWeight,{value:payload.weight_loss}),
            lostTwoWeek: _.find(state.resource.weightTwoWeek,{value:payload.weight_change}),
          },
          foodIntake: {
            intakeTotal: _.find(state.resource.intakeTotalChange,{value:payload.intake_change}),
            intakeTime: _.find(state.resource.intakeTimeChange,{value:payload.intake_change_duration}),
          },
          anoreksia: {
            status: payload.anoreksia ? '1' : '0',
            freq: _.find(state.resource.gastrointestinalFreq,{value:payload.anoreksia_frequency}),
            other: _.find(state.resource.gastrointestinalOther,{value:payload.anoreksia_duration}),
          },
          nauseous: {
            status: payload.nausea ? '1':'0',
            freq: _.find(state.resource.gastrointestinalFreq,{value:payload.nausea_frequency}),
            other: _.find(state.resource.gastrointestinalOther,{value:payload.nausea_duration}),
          },
          vomit: {
            status: payload.vomit ? '1':'0',
            freq: _.find(state.resource.gastrointestinalFreq,{value:payload.vomit_frequency}),
            other: _.find(state.resource.gastrointestinalOther, {value:payload.vomit_duration}),
          },
          diarrhea: {
            status: payload.diarrhea ? '1':'0',
            freq: _.find(state.resource.gastrointestinalFreq,{value:payload.diarrhea_frequency}),
            other: _.find(state.resource.gastrointestinalOther,{value:payload.diarrhea_frequency}),
          },
          conclusion: _.find(state.resource.conclusion, {value:payload.gastrointestinal_conclusion
          }),
          descriptionFunctional: _.find(state.resource.bodyFunctionState,{value:payload.body_function}),
          generalMetabloliscDistraction: {
            status: payload.metabolic_disorders ? '1':'0',
            category: _.find(state.resource.stressMetabolikCategory,{value:payload.category}),
          },
          physicalExam: {
            massMuscleLoss: _.find(state.resource.massMuscleLoss,{value:payload.muscle_mass_loss}),
            subcutaneousFatLoss: _.find(state.resource.subcutaneousFatLoss,{value:payload.subcutan_fat_loss}),
            edemaSelect: _.find(state.resource.edemaSelect,{value:payload.edema}),
            asitesSelect: _.find(state.resource.asitesSelect, {value:payload.asites})
          },
        }
        // console.log(_.find(state.resource.asitesSelect, {value:payload.edema}))
        // console.log(state.inputUpdate)
      }
  },
}
